import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import AboutUsTitle from "./AboutUsTitle/AboutUsTitle";
import AboutUsDark from "./AboutUsDark/AboutUsDark";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import aboutUsImg from "../../Assets/aboutUs.png";
import Preloader from "../../Components/Preloader/Preloader";
import AbousUsVectors from "./AbousUsVectors/AbousUsVectors";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { AboutUsData } from "../../Data/HeroSliderDatas";

const AboutUs = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="O nama"
        description="Upoznajte nas - Filmski Grad je mesto gde se ostvaruju snovi o nezaboravnim proslavama. Saznajte više o našoj priči, strasti prema organizaciji događaja i zašto smo prvi izbor za vaše posebne trenutke. Dobrodošli u naš svet nezaboravnih događaja!"
        canonical="https://filmskigrad.rs/o-nama"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <AbousUsVectors />
            <HeroSlider slides={AboutUsData} />
            <LayoutTextRight
              description="Mi smo vaša savršena destinacija za iznajmljivanje prostora za sve vrste proslava i događaja. Naša strast je stvaranje magičnih trenutaka za vas i vaše goste, bilo da se radi o rođendanima, venčanjima, korporativnim skupovima ili bilo kojim drugim posebnim prilikama.
  U Filmskom Gradu, svaka sala je prava scena koju možete pretvoriti u svoj vlastiti svet maštovitih ideja. Naši prostori su opremljeni kako bi odgovarali svim vašim potrebama, bilo da želite elegantnu i sofisticiranu atmosferu ili živopisnu i veselu dekoraciju."
              image={aboutUsImg}
            />
            <AboutUsTitle />
            <AboutUsDark />
            <div className="page-content"></div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default AboutUs;
