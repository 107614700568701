import React from 'react'
import './movie-star-component-seconds.css'
import unsplash from '../../../../Assets/unsplash.png'
import unsplash2 from '../../../../Assets/unsplash2.png'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MovieStarComponentSecond = () => {
  return (
    <div className="ms-component">
        <div className="ms-component-overlay">
          <LazyLoadImage src={unsplash} alt='Overlej za Filmsku Zvezdu' />
          <LazyLoadImage src={unsplash2} alt='Overlej za Filmsku Zvezdu' />
        </div>
        <div className="ms-content">
            <h3>Naš tim posvećenih stručnjaka stoji vam na raspolaganju da vam pomogne u ostvarivanju vaših vizija i stvaranju nezaboravnih uspomena. Doživite glamur i sjaj u sali "Filmska zvezda" - gde se svaka proslava pretvara u istinsku zvezdanu priču.</h3>
            <Link to='tel:+ 011 355 91 80'>Pozovite nas</Link>
        </div>
    </div>
  )
}

export default MovieStarComponentSecond