import React, { useRef, useState } from 'react'
import './single-gallery.css'
import { allGalleryCategories } from '../Gallery'
import { useParams } from 'react-router-dom'
import Layout from '../../../Layout/Layout'
import NotFound from '../../NotFound/NotFound'
import { AiOutlineCloseCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './gallery-section.css'
import MediaComponent from '../../../Components/MediaComponent/MediaComponent'

const SingleGallery = () => {

    const [modal, setModal] = useState(false)
    const [imageIndex, setImageIndex] = useState(0)
    const [playVideo, setPlayVideo] = useState(false)
    const videoRef = useRef(null)

    const openModal = (index) => {
        setImageIndex(index);
        setModal(true);
    
        const selectedImage = gallery.images[index];
        const isVideo = /\.(mp4|webm|ogg)$/i.test(selectedImage.image);
        setPlayVideo(isVideo);
    };

    const closeModal = () => {
        setModal(false)
        setPlayVideo(false)
    }

    const nextImage = () => {
        setImageIndex((currentIndex) => (currentIndex + 1) % gallery.images.length);
    };
    
    const prevImage = () => {
        setImageIndex((currentIndex) =>
        (currentIndex - 1 + gallery.images.length) % gallery.images.length
      );
    };

    const {id} = useParams()
    const gallery = allGalleryCategories.find((gallery) => gallery.title === id)

    if(!gallery) {
        return <NotFound />
    }

  return (
    <Layout>
        <div className={modal ? 'gallery-section-slider isOpen' : 'gallery-section-slider'}>
            {
                modal && (
                    <>
                    <button onClick={prevImage} className='gss-btn gss-prev'><AiOutlineLeft /></button>
                    {
                        playVideo ? (
                            <video src={gallery.images[imageIndex].image} controls autoplay ref={videoRef} ></video>
                        ) : (
                            <>
                            <MediaComponent url={gallery.images[imageIndex].image} />
                            </>
                        )
                    }
                    <AiOutlineCloseCircle onClick={closeModal} className='close-svg' />
                    <button onClick={nextImage} className='gss-btn gss-next'><AiOutlineRight /></button>
                    </>
                )
            }
        </div>
        <div className="single-gallery">
            {
                gallery.images.map((pic, i) => (
                    <div className="gallery-pics" key={i} onClick={() => openModal(i)}>
                        <MediaComponent url={pic.image} />
                    </div>
                ))
            }
        </div>
    </Layout>
  )
}

export default SingleGallery