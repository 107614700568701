import React from 'react'
import './contact-hero.css'
import ContactImg from '../../../../Assets/contact.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContactHero = () => {
  return (
    <div className="contact-hero hidden">
      <LazyLoadImage src={ContactImg} alt='Slika za kontakt hero sekciju' />
        <div className="contact-hero-overlay"></div>
        <div className="contact-hero-content">
            <div className="contact-hero-margin">
                <h1 className='hidden'>Kontaktirajte nas</h1>
                <div className='contact-hero-box hidden'><p>Posvećenost i odgovornost pri donošenju važnih odluka u svakodnevnom poslovanju</p></div>
            </div>
        </div>
    </div>
  )
}

export default ContactHero