import React, { useEffect, useState } from 'react'
import './fgband.css'
import fgBand2 from '../../Assets/fgband2.jpg'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const fgBandSlides = [
    {
        url: fgBand2
    },
]

const FGBand = () => {

    const [currentBandImg, setCurrentBandImg] = useState(0)

    useEffect(() => {
        const bandSlide = setInterval(() => {
          setCurrentBandImg((currentBandImg) =>
          currentBandImg < fgBandSlides.length - 1 ? currentBandImg + 1 : 0
          );
        }, 3000);
    
        return () => clearInterval(bandSlide);
      }, []);

  return (
    <div className='fg-band-component hidden'>
        <div className="fg-band-overlay"></div>
        <div className="fg-band-content">
            <div className="fg-band-column">
            <h1 className='hidden-left'>Mlađa FG BAND</h1>
            <h3 className='hidden-left'>"FG Band" je bend čiji je glavni član Mladen Cvetanović, talentovani pevač sa strašću prema muzici. Ovaj sastav je poznat po svojoj energičnoj izvedbi i širokom repertoaru, koji obuhvata različite žanrove. Njihov zvuk je karakterističan po izvanrednim vokalima Mladena Cvetanovića, koji svojom emotivnom interpretacijom pesama donosi posebnu notu svakom nastupu. Oni su zastupnici našeg restorana Filmskog Grada</h3>
            <Link className='hidden-left' to='/muzika'>Pogledaj još</Link>
            </div>
            <div className="fg-band-column">
                <LazyLoadImage className='circle' height={360} src={fgBandSlides[currentBandImg].url} alt="FG Band Image" />
            </div>
        </div>
    </div>
  )
}

export default FGBand