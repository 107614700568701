import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ScrollOnTop from './ScrollOnTop';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/AboutUs/AboutUs';
import Birthdays from './Pages/Birthdays/Birthdays';
import Contact from './Pages/Contact/Contact';
import Gallery from './Pages/Gallery/Gallery';
import Apartmans from './Pages/Apartmans/Apartmans';
import Music from './Pages/Music/Music';
import MovieCity from './Pages/MovieCity/MovieCity';
import MovieStar from './Pages/MovieStar/MovieStar';
import Seminars from './Pages/Seminars/Seminars';
import Weddings from './Pages/Weddings/Weddings';
import NotFound from './Pages/NotFound/NotFound';
import SingleGallery from './Pages/Gallery/SingleGallery/SingleGallery';

function App() {
  return (
    <ScrollOnTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/o-nama" element={<AboutUs />} />
        <Route path="/rođendani" element={<Birthdays />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/galerija" element={<Gallery />} />
        <Route path="/apartmani" element={<Apartmans />} />
        <Route path="/muzika" element={<Music />} />
        <Route path="/filmski-grad" element={<MovieCity />} />
        <Route path="/filmska-zvezda" element={<MovieStar />} />
        <Route path="/seminari" element={<Seminars />} />
        <Route path="/venčanja" element={<Weddings />} />
        <Route path="/galerija/:id" element={<SingleGallery />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollOnTop>
  )
}

export default App;
