import React, { useState } from 'react'
import './home-vectors-component.css'
import weddingImg from '../../../../Assets/wedding.png'
import { TfiGallery } from 'react-icons/tfi'
import { GiCottonFlower, GiStairsCake, GiCupcake, GiCakeSlice, GiBlockHouse } from 'react-icons/gi'
import { AiFillBook } from 'react-icons/ai'
import { FaBowlFood, FaMartiniGlass, FaMusic } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { IoIosHome } from 'react-icons/io'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const iconsData = [
  {
    id: 1,
    icon: <IoIosHome />,
    title: 'Sve o nama',
    link: '/o-nama'
  },
  {
    id: 2,
    icon: <GiCottonFlower />,
    title: 'Dekoracija',
    link: '/galerija'
  },
  {
    id: 3,
    icon: <GiStairsCake />,
    title: 'Prvi ples',
    link: '/galerija'
  },
  {
    id: 4,
    icon: <GiCupcake />,
    title: 'Dečiji rođendani',
    link: '/rođendani'
  },
  {
    id: 5,
    icon: <GiCakeSlice />,
    title: 'Rođendani',
    link: '/galerija'
  },
  {
    id: 6,
    icon: <AiFillBook />,
    title: 'Seminari',
    link: '/seminari'
  },
  {
    id: 7,
    icon: <FaBowlFood />,
    title: 'Ketering',
    link: '/galerija'
  },
  {
    id: 8,
    icon: <GiBlockHouse />,
    title: 'Apartmani',
    link: '/apartmani'
  },
  {
    id: 9,
    icon: <FaMartiniGlass />,
    title: 'Korporativne proslave',
    link: '/galerija'
  },
  {
    id: 10,
    icon: <FaMusic />,
    title: 'Bend',
    link: '/muzika'
  },
  {
    id: 11,
    icon: <TfiGallery />,
    title: 'Galerija',
    link: '/galerija'
  },
]

const HomeVectorsComponent = () => {

  const [activeClass, setActiveClass] = useState(0)

  const toggleActiveItem = (index) => {
    setActiveClass(index)
  }

  return (
    <div className="home-vectors-component">
      <LazyLoadImage src={weddingImg} alt="Slika mladenaca" />
      <div className="home-vectors-component-content">
        <div className="home-vectors-content">
          <div className="home-vectors-container">
            <div className="home-vectors-icon">
              {
                iconsData.map((icon, i) => (
                  <div onClick={() => toggleActiveItem(i)} className={`home-vectors-box ${i === activeClass ? 'active' : ''}`} style={{'--i': i + 1}} key={i}>
                    <span>{icon.icon}</span>
                  </div>
                ))
              }
            </div>
            <div className="home-vectors-dec">
              {
                activeClass >= 0 && (
                  <div className={`home-vectors-vector-content`}>
                    <span>{iconsData[activeClass].icon}</span>
                    <h3>{iconsData[activeClass].title}</h3>
                    <Link to={iconsData[activeClass].link}>Pogledaj još</Link>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeVectorsComponent