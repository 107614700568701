import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { SeminarsHeroData } from "../../Data/HeroSliderDatas";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import seminarsImg from "../../Assets/Seminars/seminarsImageFirst.png";
import SeminarsVectors from "./Components/SeminarsVectors/SeminarsVectors";
import seminarsImg2 from "../../Assets/Seminars/seminarsComponent.png";
import Preloader from "../../Components/Preloader/Preloader";

const Seminars = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  // Loader state
  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Seminari"
        description="Seminari u Filmskom Gradu - Vaša platforma za uspešno poslovno okupljanje. Naša prostrana sala i moderna oprema pružaju savršeno okruženje za produktivne seminare. Istražite našu ponudu i ostvarite vrhunski seminar uz nas!"
        canonical="https://filmskigrad.rs/seminari"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <HeroSlider slides={SeminarsHeroData} />
            <div className="page-content">
              <LayoutTextRight
                image={seminarsImg}
                description="Dobrodošli u našu savremeno opremljenu salu za seminare, gde se znanje deli, ideje rađaju i inovacija cveta! Naša Inspirativna sala je idealno okruženje za produktivne i edukativne događaje.
  Opremljena najsavremenijom audiovizuelnom tehnologijom, udobnim sedištima i funkcionalnim radnim prostorima, ova sala je stvorena da podrži interaktivne diskusije, prezentacije i radionice. Prirodno osvetljenje doprinosi pozitivnoj atmosferi, a modularni raspored stolova omogućava prilagođavanje prostora potrebama vašeg seminara."
              />
            </div>
            <SeminarsVectors />
            <div className="page-content">
              <LayoutTextRight
                image={seminarsImg2}
                description='Sala za seminare "Filmski Grad" je idealno mesto za organizaciju produktivnih i inspirativnih seminara, radionica i edukativnih događaja. Ova prostrana sala je pažljivo dizajnirana kako bi pružila udobnost, funkcionalnost i tehnološke mogućnosti potrebne za uspešno održavanje seminara različitih vrsta. Sala ima kapacitet za 160 do 320 učesnika, zavisno o postavci stolova i stolica. Ova fleksibilnost omogućava prilagodljivost za različite vrste događaja.'
              />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Seminars;
