import React from 'react'
import './vectors.css'
import { Link } from 'react-router-dom'

const Vectors = ({vectors}) => {
  return (
    <div className="vectors-component">
        <div className="vectors-grid hidden">
            {
                vectors.map((vector, i) => (
                    <Link to={vector.link} className="vector-column" key={i} vector={vector}>
                        <span>{vector.icon}</span>
                        <h2>{vector.title}</h2>
                    </Link>
                ))
            }
        </div>
    </div>
  )
}

export default Vectors