import WeddingImg2 from '../Assets/Weddings/wedding2.png'
import WeddingImg3 from '../Assets/Weddings/wedding3.png'
import WeddingImg4 from '../Assets/Weddings/wedding4.png'
import WeddingImg5 from '../Assets/Weddings/wedding5.png'

import SeminarsImg1 from '../Assets/Seminars/seminars1.png'
import SeminarsImg2 from '../Assets/Seminars/seminars2.png'
import SeminarsImg3 from '../Assets/Seminars/seminars3.png'
import SeminarsImg4 from '../Assets/Seminars/seminars4.png'
import SeminarsImg5 from '../Assets/Seminars/seminars5.png'

import Birthday2Img from '../Assets/Birthdays/birthday2.jpg'
import Birthday3Img from '../Assets/Birthdays/birthday3.jpg'
import Birthday4Img from '../Assets/Birthdays/birthday4.jpg'
import Birthday5Img from '../Assets/Birthdays/birthday5.jpg'

import MovieCity from '../Assets/MC4.png'
import MovieStar from '../Assets/MovieStar/MSG8.png'
import Weddings from '../Assets/Weddings/wg2.png'
import Birthdays from '../Assets/Birthdays/birthday2.jpg'
import Seminars from '../Assets/Seminars/homeHeroSeminars.png'

import FGBand1Img from '../Assets/fgband1.jpg'
import FGBand2Img from '../Assets/fgband2.jpg'
import FGBand3Img from '../Assets/fgband3.jpg'

import gallerImg1 from '../Assets/firstDance.png'
import gallerImg2 from '../Assets/firstDance2.png'

import DecorationImg1 from '../Assets/Decoration1.jpg'
import DecorationImg2 from '../Assets/Decoration2.jpg'
import DecorationImg3 from '../Assets/Decoration3.jpg'
import DecorationImg4 from '../Assets/Decoration4.jpg'

export const WeddingsHeroData = [
    {
        id: 1,
        image: WeddingImg2,
        title: 'Venčanja',
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izvanredne sale za proslavu rođendana, stvarajući nezaboravan ambijent za vaš poseban dan.'
    },
    {
        id: 2,
        image: WeddingImg3,
        title: 'Venčanja',
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izvanredne sale za proslavu rođendana, stvarajući nezaboravan ambijent za vaš poseban dan.'
    },
    {
        id: 3,
        image: WeddingImg4,
        title: 'Venčanja',
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izvanredne sale za proslavu rođendana, stvarajući nezaboravan ambijent za vaš poseban dan.'
    },
    {
        id: 4,
        image: WeddingImg5,
        title: 'Venčanja',
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izvanredne sale za proslavu rođendana, stvarajući nezaboravan ambijent za vaš poseban dan.'
    },
]

export const SeminarsHeroData = [
    {
        id: 1,
        image: SeminarsImg1,
        title: 'Seminari',
        desc: 'Sala za seminare koju izdaje restoran Filmski Grad predstavlja izvanredan prostor za vaše poslovne događaje i edukativne seminare. Ova sala kombinuje udobnost i funkcionalnost, pružajući inspirativno okruženje za konstruktivan rad i učenje.'
    },
    {
        id: 2,
        image: SeminarsImg2,
        title: 'Seminari',
        desc: 'Sala za seminare koju izdaje restoran Filmski Grad predstavlja izvanredan prostor za vaše poslovne događaje i edukativne seminare. Ova sala kombinuje udobnost i funkcionalnost, pružajući inspirativno okruženje za konstruktivan rad i učenje.'
    },
    {
        id: 3,
        image: SeminarsImg3,
        title: 'Seminari',
        desc: 'Sala za seminare koju izdaje restoran Filmski Grad predstavlja izvanredan prostor za vaše poslovne događaje i edukativne seminare. Ova sala kombinuje udobnost i funkcionalnost, pružajući inspirativno okruženje za konstruktivan rad i učenje.'
    },
    {
        id: 4,
        image: SeminarsImg4,
        title: 'Seminari',
        desc: 'Sala za seminare koju izdaje restoran Filmski Grad predstavlja izvanredan prostor za vaše poslovne događaje i edukativne seminare. Ova sala kombinuje udobnost i funkcionalnost, pružajući inspirativno okruženje za konstruktivan rad i učenje.'
    },
    {
        id: 5,
        image: SeminarsImg5,
        title: 'Seminari',
        desc: 'Sala za seminare koju izdaje restoran Filmski Grad predstavlja izvanredan prostor za vaše poslovne događaje i edukativne seminare. Ova sala kombinuje udobnost i funkcionalnost, pružajući inspirativno okruženje za konstruktivan rad i učenje.'
    },
]

export const BirthdaysHeroData = [
    {
        id: 1,
        image: Birthday2Img,
        title: "Rođendani",
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izuzetne sale za proslave rođendana, pružajući nezaboravno iskustvo za vaše posebne trenutke.'
    },
    {
        id: 2,
        image: Birthday3Img,
        title: "Rođendani",
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izuzetne sale za proslave rođendana, pružajući nezaboravno iskustvo za vaše posebne trenutke.'
    },
    {
        id: 3,
        image: Birthday4Img,
        title: "Rođendani",
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izuzetne sale za proslave rođendana, pružajući nezaboravno iskustvo za vaše posebne trenutke.'
    },
    {
        id: 4,
        image: Birthday5Img,
        title: "Rođendani",
        desc: '"Filmski Grad" i "Filmska Zvezda" su dve fantastične lokacije koje nude izuzetne sale za proslave rođendana, pružajući nezaboravno iskustvo za vaše posebne trenutke.'
    },
]

export const allHomeSlides = [
    {
        id: 1,
        title: 'Filmski Grad',
        link: '/filmski-grad',
        desc: 'Sala "Filmski Grad" je jedinstveni i uzbudljivi prostor koji, uprkos svom nazivu, nije povezan s filmskom industrijom, već nudi izuzetno raznoliku i prilagodljivu lokaciju za razne vrste proslava i događanja.',
        image: MovieCity
    },
    {
        id: 2,
        title: 'Filmska Zvezda',
        link: '/filmska-zvezda',
        desc: 'Sala "Filmska Zvezda" je elegantan i šarmantan prostor koji odiše glamurom i sofisticiranim šarmom, koji je uvek prepoznatljiv za sebe.',
        image: MovieStar
    },
    {
        id: 3,
        title: 'Venčanja',
        link: '/venčanja',
        desc: 'Naše svadbene sale su čarobna mesta gde se snovi o večnoj ljubavi ostvaruju. Ove prelepe prostore karakteriše elegancija i šarm, stvarajući savršen ambijent za slavlje ljubavi i zajedničkog početka.',
        image: Weddings
    },
    {
        id: 4,
        title: 'Rođendani',
        link: '/rođendani',
        desc: 'Naša raznolika ponuda sala pruža neograničene mogućnosti za pravljenje savršene zabave. Da li je vaš mali superheroj spreman za akciju?',
        image: Birthdays
    },
    {
        id: 5,
        title: 'Seminari',
        link: '/seminari',
        desc: 'Dobrodošli u našu savremeno opremljenu salu za seminare, gde se znanje deli, ideje rađaju i inovacija cveta! Naša Inspirativna Učionica je idealno okruženje za produktivne i edukativne događaje.',
        image: Seminars
    },
]

export const MusicData = [
    {
        id: 1,
        title: 'Bend',
        image: FGBand1Img,
        desc: 'Ovaj talentovani bend je sastavni deo ponude, često nastupajući na proslavama koje se održavaju u sobama ovog prostora. Sa širokim repertoarom i strastvenim izvođenjem'
    },
    {
        id: 2,
        title: 'Bend',
        image: FGBand2Img,
        desc: 'Ovaj talentovani bend je sastavni deo ponude, često nastupajući na proslavama koje se održavaju u sobama ovog prostora. Sa širokim repertoarom i strastvenim izvođenjem'
    },
    {
        id: 3,
        title: 'Bend',
        image: FGBand3Img,
        desc: 'Ovaj talentovani bend je sastavni deo ponude, često nastupajući na proslavama koje se održavaju u sobama ovog prostora. Sa širokim repertoarom i strastvenim izvođenjem'
    },
]

export const GallerySliderData = [
    {
        id: 1,
        title: 'Prvi ples',
        image: gallerImg1,
        desc: "Prvi mladenački ples na venčanju je čaroban trenutak koji simbolizira početak zajedničkog života mladenaca. Ovaj poseban trenutak često se odvija nakon ceremonije venčanja, obično tokom svečanog prijema ili večere."
    },
    {
        id: 2,
        title: 'Prvi ples',
        image: gallerImg2,
        desc: "Prvi mladenački ples na venčanju je čaroban trenutak koji simbolizira početak zajedničkog života mladenaca. Ovaj poseban trenutak često se odvija nakon ceremonije venčanja, obično tokom svečanog prijema ili večere."
    },
]

export const AboutUsData = [
    {
        id: 1,
        title: 'Dekoracija prostora i svečanih sala',
        image: DecorationImg1,
        desc: 'Dekoracija prostora u "Filmskom Gradu" je pažljivo osmišljena kako bi stvorila glamuroznu i sofisticiranu atmosferu. Velika pažnja se posvećuje detaljima, što rezultuje prelepim enterijerom.',
    },
    {
        id: 2,
        title: 'Dečija dekoracija',
        image: DecorationImg2,
        desc: 'Decije dekoracije u restoranu "Filmski Grad" predstavljaju čaroban svet igre, boja i maštovitosti. Ovaj restoran nudi posebne dekoracije i svecane sale koje su dizajnirane da stvore veselu i prijateljsku atmosferu za decije proslave.',
    },
    {
        id: 3,
        title: 'Dekoracija za 18-ti rođendan',
        image: DecorationImg3,
        desc: 'Dekoracije za 18. rođendan u restoranu "Filmski Grad" predstavljaju spoj mladalačkog entuzijazma, elegancije i stila. Ovaj restoran pruža posebne dekoracije i svecane sale koje će pomoći u stvaranju nezaboravne atmosfere za slavlje punoletstva.',
    },
    {
        id: 4,
        title: 'Agregati',
        image: DecorationImg4,
        desc: 'Restoran Filmski Grad je opremljen visokokvalitetnim agregatom koji osigurava neprekidno snabdevanje električnom energijom. Naš pouzdani agregat obezbeđuje sigurnost i kontinuitet rada restorana čak i tokom neočekivanih prekida struje. Na taj način, možemo da vam pružimo neprekinutu uslugu i ugodno iskustvo obroka, bez obzira na vremenske ili tehničke izazove',
    },
]