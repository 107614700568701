import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const HelmetContent = ({title, description, canonical, google}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${title} - Filmski Grad`}</title>
        <meta name='description' content={description}/>
        <meta name='google-site-verification' content={google} />
        <link rel="canonical" href={canonical} />
      </Helmet>
    </HelmetProvider>
  )
}

export default HelmetContent