import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import Hero from "../../Components/Hero/Hero";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import movieCityImg from "../../Assets/MovieCity/MCG29.jpg";
import movieCityImg2 from "../../Assets/MovieCity/MCG26.jpeg";
import Vectors from "../../Components/Vectors/Vectors";
import { HomeVectors } from "../../Data/VectorsData";
import FGBand from "../../Components/FGBand/FGBand";
import FullGallery from "../../Components/GalleryComponent/FullGallery/FullGallery";
import { movieCityGallery } from "../../Data/GalleryData";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import "./movie-city.css";
import Preloader from "../../Components/Preloader/Preloader";
import MCGImg from '../../Assets/MovieCity/MCG28.jpg'

const MovieCity = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Sala"
        description="Sala Filmski Grad - Vaša elegantna i prilagodljiva destinacija za proslave i događaje. Prepustite se luksuznom ambijentu, izvanrednoj kulinarskoj ponudi i besprekornoj usluzi. Rezervišite Filmski Grad za svoj nezaboravan trenutak danas!"
        canonical="https://filmskigrad.rs/filmski-grad"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <Hero
            image={MCGImg}
              title="Filmski Grad"
              description='"Filmski Grad Sala - Vaša destinacija za nezaboravne proslave! Smestite svoj događaj u prelepu i elegantnu salu unutar restorana Filmski Grad. Bez obzira na vrstu proslave, bilo da je u pitanju venčanje, rođendan, godišnjica ili korporativni događaj, naša sala vam pruža savršen ambijent za sve vaše potrebe.'
              link={"/filmski-grad"}
            />
            <div className="page-content mc-page-content">
              {/* <img className="movie-city-dec-1" src={DecorativeImg} alt="Dekorativna slika" /> */}
              <LayoutTextRight
                image={movieCityImg}
                description='Sala "Filmski Grad" može prilagoditi svoj raspored i dekor prema potrebama događaja. Bez obzira na to želite li organizirati venčanje, korporativni seminar, rođendansku proslavu ili bilo koju drugu vrstu događanja, ova sala će se prilagoditi vašim željama. Fleksibilan raspored stolova i stolica omogućava smeštaj različitog broja gostiju, a veliki plesni podijum čini ovu salu idealnom za zabave i plesne večeri.'
              />
              {/* <img className="movie-city-dec-3" loading="lazy" src={DecorativeImg3} alt="Dekorativna slika" />
                <img className="movie-city-dec-2" src={DecorativeImg2} alt="Dekorativna slika" /> */}
              <LayoutTextLeft
                image={movieCityImg2}
                description='Sala takođe nudi dobro opremljen bar s bogatim izborom pića, uključujući koktele i lokalna vina, kako bi se gosti mogli opustiti i uživati u druženju.
  
  Za praktične potrebe, "Filmski Grad" ima moderne toalete i garderobu te tehničku opremu za glazbu i prezentacije. Osoblje sale je posvećeno pružanju vrhunske usluge kako bi vaša proslava bila što glađa i bez stresa.'
              />
              <Vectors vectors={HomeVectors} />
            </div>
            <FGBand />
            <div className="page-content">
              <FullGallery title="Filmskog Grada" data={movieCityGallery} />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default MovieCity;
