import React from 'react'
import './layout-text-right.css'
import { useLocation } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LayoutTextRight = ({image, description, decoration}) => {

  const location = useLocation()

  return location.pathname === '/filmska-zvezda' ?  (
    <div className="layout-text-right">
        <div className="layout-text-right-column hidden-left">
            <div className="layout-text-right-box">
              <LazyLoadImage src={image} alt='Slika pored sajta' height={480} />
            </div>
        </div>
        <div className="layout-text-right-column hidden-right">
        <LazyLoadImage src={decoration} alt='Dekorativna slika' height={600} />
          <p className='pink'>{description}</p>
        </div>
    </div>
  ) : (
    <div className="layout-text-right">
    <div className="layout-text-right-column hidden-left">
        <div className="layout-text-right-box">
        <LazyLoadImage src={image} alt='Slika pored sajta' height={480} />
        </div>
    </div>
    <div className="layout-text-right-column hidden-right">
        <p>{description}</p>
    </div>
</div>
  )
}

export default LayoutTextRight