import React, { useEffect, useState } from "react";
import "./movie-star-component.css";
import MovieStarOverlay from "../../../../Assets/MovieStarAbstract.jpg";
import Unsplash from "../../../../Assets/unsplash.png";
import MovieStarImg1 from "../../../../Assets/MovieStar/MSG26.jpeg";
import MovieStarImg2 from "../../../../Assets/MovieStar/MSG27.jpeg";
import MovieStarImg3 from "../../../../Assets/MovieStar/MSG28.jpg";
import MovieStarImg4 from "../../../../Assets/MovieStar/MSG29.jpeg";
import MovieStarImg5 from "../../../../Assets/MovieStar/MSG30.jpeg";
import { Link } from "react-router-dom";
import { FaPhone } from 'react-icons/fa6'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const slides = [
  {
    url: MovieStarImg1,
  },
  {
    url: MovieStarImg2,
  },
  {
    url: MovieStarImg3,
  },
  {
    url: MovieStarImg4,
  },
  {
    url: MovieStarImg5,
  },
];

const MovieStarComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const movieStarSlide = setInterval(() => {
      setCurrentIndex((currentIndex) =>
        currentIndex < slides.length - 1 ? currentIndex + 1 : 0
      );
    }, 3000);

    return () => clearInterval(movieStarSlide);
  }, []);

  return (
    <div className="movie-star-component">
      <LazyLoadImage className="msc-dec" src={MovieStarOverlay} alt="Dekorativna slika" height={600} />
      <LazyLoadImage className="msc-dec" src={Unsplash} alt="Dekorativna slika" height={600} />
      <div className="movie-star-component-content">
        <div className="movie-star-component-grid">
          <div className="movie-star-component-column-1">
            <h1 className="msc-64 hidden-left">
              Zvezda Srebrnog Platna: Uplovite u svet magije u sali
            </h1>
            <h1 className="msc-96 hidden-left">"FILMSKA ZVEZDA"</h1>
            <div className="msc-btn hidden-left">
              <Link to="/filmska-zvezda">Pogledaj još</Link>
            </div>
          </div>
          <div className="movie-star-component-column-2">
            <div
              className="movie-star-component-slide-div hidden-right"
              style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
            ></div>
            <div
              className="movie-star-component-slide-div hidden-right"
              style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
            ><Link to="tel:+011 35 59 180" className="movie-star-call"><div className="movie-star-gold"><FaPhone /></div></Link></div>
            <div
              className="movie-star-component-slide-div hidden-right"
              style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
            ></div>
              <div className="movie-star-box hidden-right">
                <p>
                  Za više informacija i kako da rezervišete datum za vašu
                  proslavu, slobodno nas kontaktirajte
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieStarComponent;
