import React from "react";
import "./hero.css";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = ({video, title, description, link, image}) => {
  const location = useLocation()
  return location.pathname === '/filmska-zvezda' ? (
    <div className="hero-section hidden">
      <div className="hero-overlay"></div>
      <video src={video} autoPlay muted loop loading='lazy' />
      <div className="hero-content">
        <div className="hero-content-items">
          <h1 className="hidden-left pink">{title}</h1>
          <p className="hidden-left pink-desc">
            {description}
          </p>
          <div className="hero-btn-anim hidden-left">
            <Link to={link} className="hero-btn hero-btn-pink">Pogledajte još</Link>
          </div>
        </div>
      </div>
    </div>
  ) : location.pathname === '/filmski-grad' ? (
    <div className="hero-section hidden">
    <div className="hero-overlay"></div>
    <LazyLoadImage src={image} alt="Slika sale Filmskog grada" />
    <div className="hero-content">
      <div className="hero-content-items">
        <h1 className="hidden-left">{title}</h1>
        <h2 className="hidden-left">Kapacitet 320 osoba</h2>
        <p className="hidden-left movie-city-p">
          {description}
        </p>
      </div>
    </div>
  </div>
  ) : (
    <div className="hero-section hidden">
    <div className="hero-overlay"></div>
    <video src={video} autoPlay muted loop loading='lazy' />
    <div className="hero-content">
      <div className="hero-content-items home-page-content-items">
        <h1 className="hidden-left hph-h1">{title}</h1>
        <h2>Kapacitet 320 osoba</h2>
      </div>
    </div>
  </div>
  )
};

export default Hero;
