import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import "./gallery.css";
import GalleryCards from "./GalleryCards/GalleryCards";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { GallerySliderData } from "../../Data/HeroSliderDatas";
import Preloader from "../../Components/Preloader/Preloader";

import gallery1 from "../../Assets/MC1.png";
import gallery2 from "../../Assets/MovieStar/movieStarV4.png";
import gallery3 from "../../Assets/Weddings/wedding5.png";

import MCG26 from "../../Assets/MovieCity/MCG26.jpeg";
import MCG27 from "../../Assets/MovieCity/MCG27.jpeg";
import MCG28 from "../../Assets/MovieCity/MCG28.jpg";
import MCG29 from "../../Assets/MovieCity/MCG29.jpg";
import MCG30 from "../../Assets/MovieCity/MCG30.jpg";
import MCG31 from "../../Assets/MovieCity/MCG31.jpeg";
import MCG32 from "../../Assets/MovieCity/MCG32.jpeg";

import MSG26 from "../../Assets/MovieStar/MSG26.jpeg";
import MSG27 from "../../Assets/MovieStar/MSG27.jpeg";
import MSG28 from "../../Assets/MovieStar/MSG28.jpg";
import MSG29 from "../../Assets/MovieStar/MSG29.jpeg";
import MSG30 from "../../Assets/MovieStar/MSG30.jpeg";
import MSG31 from "../../Assets/MovieStar/MSG31.jpeg";
import MSG32 from "../../Assets/MovieStar/MSG32.jpg";
import MSG33 from "../../Assets/MovieStar/MSG33.jpeg";
import MSG34 from "../../Assets/MovieStar/MSG34.jpeg";
import MSG35 from "../../Assets/MovieStar/MSG35.jpeg";

import WG1 from "../../Assets/Weddings/wg1.png";
import WG2 from "../../Assets/Weddings/wg2.png";
import WG3 from "../../Assets/Weddings/wg3.png";
import WG4 from "../../Assets/Weddings/wg4.png";
import WG5 from "../../Assets/Weddings/wg5.png";
import WG6 from "../../Assets/Weddings/wg6.png";
import WG7 from "../../Assets/Weddings/wg7.png";
import WG8 from "../../Assets/Weddings/wg8.png";
import WG9 from "../../Assets/Weddings/wg9.png";
import WG10 from "../../Assets/Weddings/wg10.png";

import DG1 from '../../Assets/Decoration1.jpg'
import DG2 from '../../Assets/Decoration2.jpg'
import DG3 from '../../Assets/Decoration3.jpg'
import DG4 from '../../Assets/Decoration4.jpg'
import DG5 from '../../Assets/Decoration5.jpg'
import DG6 from '../../Assets/Decoration6.jpg'

import CG1 from '../../Assets/Catering1.png'
import CG2 from '../../Assets/Catering2.png'
import CG3 from '../../Assets/Catering3.png'
import CG4 from '../../Assets/Catering4.png'

import OCG1 from '../../Assets/openConcept1.png'
import OCG2 from '../../Assets/openConcept2.png'
import OCG3 from '../../Assets/OpenConcept3.png'
import OCG5 from '../../Assets/OpenConcept5.png'
import OCG7 from '../../Assets/OpenConcept7.png'
import OCG10 from '../../Assets/openConcept10.jpg'
import OCG11 from '../../Assets/openConcept11.jpg'

import EGBirthday1 from '../../Assets/18.mp4'
import EGBirthday2 from '../../Assets/18-2.mp4'
import EGBirthday3 from '../../Assets/Melodies/musicVideo12.mp4'
import EGBirthdayImg from '../../Assets/Birthdays/birthday5.jpg'

import musicVideo1 from '../../Assets/Melodies/Melody1.mp4'
import musicVideo2 from '../../Assets/Melodies/Melody2.mp4'
import musicVideo3 from '../../Assets/Melodies/Melody3.mp4'
import musicVideo4 from '../../Assets/Melodies/musicVideo.mp4'
import musicVideo5 from '../../Assets/Melodies/musicVideo2.mp4'
import musicVideo6 from '../../Assets/Melodies/musicVideo3.mp4'
import musicVideo7 from '../../Assets/Melodies/musicVideo4.mp4'
import musicVideo8 from '../../Assets/Melodies/musicVideo5.mp4'
import musicVideo9 from '../../Assets/Melodies/musicVideo6.mp4'
import musicVideo10 from '../../Assets/Melodies/musicVideo7.mp4'
import musicVideo11 from '../../Assets/Melodies/musicVideo8.mp4'
import musicVideo12 from '../../Assets/Melodies/musicVideo9.mp4'
import musicVideo13 from '../../Assets/Melodies/musicVideo10.mp4'
import musicVideo14 from '../../Assets/Melodies/musicVideo11.mp4'
import musicImg from '../../Assets/fgband1.jpg'

export const allGalleryCategories = [
  {
    id: 1,
    title: "Filmski Grad",
    img: gallery1,
    images: [
      {
        id: 26,
        image: MCG26,
      },
      {
        id: 27,
        image: MCG27,
      },
      {
        id: 28,
        image: MCG28,
      },
      {
        id: 29,
        image: MCG29,
      },
      {
        id: 30,
        image: MCG30,
      },
      {
        id: 31,
        image: MCG31,
      },
      {
        id: 32,
        image: MCG32,
      },
    ],
  },
  {
    id: 2,
    title: "Filmska Zvezda",
    img: gallery2,
    images: [
      {
        id: 26,
        image: MSG26,
      },
      {
        id: 27,
        image: MSG27,
      },
      {
        id: 28,
        image: MSG28,
      },
      {
        id: 29,
        image: MSG29,
      },
      {
        id: 30,
        image: MSG30,
      },
      {
        id: 31,
        image: MSG31,
      },
      {
        id: 32,
        image: MSG32,
      },
      {
        id: 33,
        image: MSG33,
      },
      {
        id: 34,
        image: MSG34,
      },
      {
        id: 35,
        image: MSG35,
      },
    ],
  },
  {
    id: 3,
    title: "Venčanja",
    img: gallery3,
    images: [
      {
        id: 1,
        image: WG1,
      },
      {
        id: 2,
        image: WG2,
      },
      {
        id: 3,
        image: WG3,
      },
      {
        id: 4,
        image: WG4,
      },
      {
        id: 5,
        image: WG5,
      },
      {
        id: 6,
        image: WG6,
      },
      {
        id: 7,
        image: WG7,
      },
      {
        id: 8,
        image: WG8,
      },
      {
        id: 9,
        image: WG9,
      },
      {
        id: 10,
        image: WG10,
      },
    ],
  },
  {
    id: 4,
    title: 'Dekoracija',
    img: DG1,
    images: [
      {
        id: 1,
        image: DG1
      },
      {
        id: 2,
        image: DG2
      },
      {
        id: 3,
        image: DG3
      },
      {
        id: 4,
        image: DG4
      },
      {
        id: 5,
        image: DG5
      },
      {
        id: 6,
        image: DG6
      },
    ]
  },
  {
    id: 5,
    title: 'Ketering',
    img: CG1,
    images: [
      {
        id: 1,
        image: CG1
      },
      {
        id: 2,
        image: CG2
      },
      {
        id: 3,
        image: CG3
      },
      {
        id: 4,
        image: CG4
      },
    ]
  },
  {
    id: 6,
    title: 'Open Koncept Svadbe',
    img: OCG1,
    images: [
      {
        id: 1,
        image: OCG1
      },
      {
        id: 2,
        image: OCG2
      },
      {
        id: 3,
        image: OCG3
      },
      {
        id: 5,
        image: OCG5
      },
      {
        id: 7,
        image: OCG7
      },
      {
        id: 10,
        image: OCG10
      },
      {
        id: 11,
        image: OCG11
      },
    ]
  },
  {
    id: 7,
    title: '18 Rođendani',
    img: EGBirthdayImg,
    images: [
      {
        id: 1,
        image: EGBirthday1
      },
      {
        id: 2,
        image: EGBirthday2
      },
      {
        id: 3,
        image: EGBirthday3
      },
    ]
  },
  {
    id: 8,
    title: 'Muzika',
    img: musicImg,
    images: [
      {
        id: 1,
        image: musicVideo1
      },
      {
        id: 2,
        image: musicVideo2
      },
      {
        id: 3,
        image: musicVideo3
      },
      {
        id: 4,
        image: musicVideo4
      },
      {
        id: 5,
        image: musicVideo5
      },
      {
        id: 6,
        image: musicVideo6
      },
      {
        id: 7,
        image: musicVideo7
      },
      {
        id: 8,
        image: musicVideo8
      },
      {
        id: 9,
        image: musicVideo9
      },
      {
        id: 10,
        image: musicVideo10
      },
      {
        id: 11,
        image: musicVideo11
      },
      {
        id: 12,
        image: musicVideo12
      },
      {
        id: 13,
        image: musicVideo13
      },
      {
        id: 14,
        image: musicVideo14
      },
    ]
  }
];

const Gallery = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Galerija"
        canonical="https://filmskigrad.rs/galerija"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
        description="Razgledajte našu galeriju - Filmski Grad kroz objektiv kamere. Otkrijte lepotu i eleganciju naših prostora, uživajte u trenucima sreće i slavlja. Pregledajte naše slike i doživite unikatnu atmosferu koju nudimo za vaše posebne trenutke."
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <HeroSlider slides={GallerySliderData} />
            <div className="page-content">
              <GalleryCards />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Gallery;
