import React, { useState } from 'react'
import './contact-form.css'
import axios from 'axios';

const ContactForm = () => {

    // State variable for each input
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        title: '',
        message: '',
    })

    // Event handlers for each input
    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value)
    }

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault()

        const emailData = {
            name: name,
            email: email,
            title: title,
            message: message
        }

        // Validation
        const newErrors = {};

        if(!name || name.length < 6) {
            newErrors.name = 'Ime mora da ima najmanje 6 karaktera'
        }

        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {  // Basic email validation
            newErrors.email = 'Imejl adresa nije validna';
        }

        if (!title || title.length < 6) {
            newErrors.title = 'naslov mora biti najmanje 6 karaktera';
        }
      
        if (!message || message.length > 200) {
            newErrors.message = 'Poruka mora biti manja od 200 karaktera';
        }

        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            return;
        }

        try {
            const response = await axios.post('./http://localhost:5000/api/form', emailData)
            console.log("Response", response.data)

            setName('')
            setEmail('')
            setTitle('')
            setMessage('')
            setErrors({})
        }catch (error) {
            console.error("Error", error)
        }
    }

  return (
    <div className="contact-form">
        <div className="contact-column">
            <form className='form' action="" onSubmit={handleSubmit}>
                {/*----- Single Input -----*/}
                <div className="single-contact-input hidden-left">
                    <label htmlFor="name">Vaše ime</label>
                    <input value={name} id='name' autoComplete='off' onChange={handleNameChange} type="text" name='name' placeholder='Unesite vaše ime' />
                    <div className={`error ${errors.name ? 'visible' : ''}`}>{errors.name}</div>
                </div>
                {/*----- End of Single Input -----*/}
                {/*----- Single Input -----*/}
                <div className="single-contact-input hidden-left">
                    <label htmlFor="email">Vaša imejl adresa</label>
                    <input value={email} id='email' autoComplete='off' onChange={handleEmailChange} type="email" name='email' placeholder='Unesite vašu imejl adresu' />
                    <div className={`error ${errors.email ? 'visible' : ''}`}>{errors.email}</div>
                </div>
                {/*----- End of Single Input -----*/}
                {/*----- Single Input -----*/}
                <div className="single-contact-input hidden-left">
                    <label htmlFor="title">Naslov</label>
                    <input value={title} id='title' autoComplete='off' onChange={handleTitleChange} type="text" name='title' placeholder='Unesite vaš naslov' />
                    <div className={`error ${errors.title ? 'visible' : ''}`}>{errors.title}</div>
                </div>
                {/*----- End of Single Input -----*/}
                {/*----- Single Input -----*/}
                <div className="single-contact-input hidden-left">
                    <label htmlFor="message">Vaša poruka</label>
                    <textarea value={message} id='message' autoComplete='off' onChange={handleMessageChange} name="message" placeholder='Unesite vašu poruku'></textarea>
                    <div className={`error ${errors.message ? 'visible' : ''}`}>{errors.message}</div>
                </div>
                {/*----- End of Single Input -----*/}
                <button type='submit' className='hidden-left'>Pošalji</button>
            </form>
        </div>
        <div className="contact-column contact-column-2">
            <h1 className='contact-text hidden-right'>Tu smo da vam pomognemo</h1>
            <h2 className='contact-text hidden-right'>budite slobdni da nas kontaktirate kad vam to odgovara.Potrudićemo se da vam odgovorimo što pre možemo.</h2>
            <p className='contact-text hidden-right'>Vaša poruka biče prosleđena menadzeru restorana</p>
            <h1 className='contact-text hidden-right'>Restoran "Filmski Grad"</h1>
            <h3 className='contact-text hidden-right'>Kneza Višeslava 88, Beograd 11090</h3>
            <h2 className='contact-text hidden-right'>011 3559180</h2>
        </div>
    </div>
  )
}

export default ContactForm