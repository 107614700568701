import React from "react";
import "./open-concept.css";
import openConcept1 from "../../../../Assets/openConcept1.png";
import openConcept2 from "../../../../Assets/openConcept2.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OpenConcept = () => {
  return (
    <div className="open-concept-component">
      <div className="open-concept-title hidden">
        <h1>Open Koncept Svadbe</h1>
      </div>
      <div className="open-concept-grid">
        <div className="open-concept-column hidden-left">
          <LazyLoadImage src={openConcept1} alt="Open koncept svadbe" height={300} />
        </div>
        <div className="open-concept-column occ-text hidden-right">
          <h3>
            Open Concept prostori prilagođeni su parovima koji žele maksimalnu
            autentičnost, drugačije iskustvo i uslugu vrhusnkog nivoa. Pažljivo
            osmišljen dizajn nudi široku mogućnost za realizaciju čak i
            najmanjih detalja.Naš tim profesionalaca sa dugogodišnjim iskustvom,
            približiće mladencima misiju pomeranja granica, dok ćemo zajedničkim
            idejama težiti da dostignemo najviši stepen jedinstvenosti po kom će
            vaše venčanje biti upamćeno.
          </h3>
        </div>
        <div className="open-concept-column occ-text hidden-left">
          <h3>
            Skladnost prirode i urbanih elemenata oslikava svaki Open Concept
            prostor. Otvoreni vrtovi idealan su izbor za ceremoniju venčanja,
            dok su zatvoreni prostori projektovani tako da svaka zvanica uživa u
            svečanom prijemu koji uvodi u trenutke pune plesa, zagrljaja, osmeha
            i dobrog raspoloženja.
          </h3>
        </div>
        <div className="open-concept-column hidden-right">
        <LazyLoadImage src={openConcept2} alt="Open koncept svadbe" height={300} />
        </div>
      </div>
    </div>
  );
};

export default OpenConcept;
