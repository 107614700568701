import React from 'react'
import './weddings-component.css'
import flowers from '../../../Assets/flowers.png'
import weddings from '../../../Assets/Weddings/wedding4.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const WeddingsComponent = () => {
  return (
    <div className="weddings-component">
      <img className='decorative-img hidden' src={flowers} alt="Dekorativna slika cveća" />
      <div className="weddings-component-content">
        <div className="weddings-component-column">
          <LazyLoadImage src={weddings} alt='Slika venčanja' />
          <div className="weddings-component-box hidden">
            <h3>Spremno vas dočekuje elegantna i šarmantna sala za proslave venčanja, savršeno okruženje za vaš poseban dan. Prostrani enterijer pruža dovoljno mesta za sve vaše goste, dok prefinjeni dekor i sofisticirana osvetljenost stvaraju bajkovitu atmosferu. Udoban ambijent omogućava neograničene mogućnosti za personalizaciju, bilo da želite klasičnu ili modernu temu. Pored toga, visoko kvalifikovano osoblje je tu da zadovolji sve vaše potrebe, pružajući nezaboravno iskustvo venčanja za vas i vaše goste.</h3>
          </div>
        </div>
        <div className="weddings-component-column"></div>
      </div>
      <img className='decorative-img hidden' src={flowers} alt="Dekorativna slika cveća" />
    </div>
  )
}

export default WeddingsComponent