import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MediaComponent = ({url}) => {

    const isImage = /\.(jpeg|jpg|gif|png)$/i.test(url);
    const isVideo = /\.(mp4|webm|ogg)$/i.test(url);

    if(isImage) {
        return <LazyLoadImage src={url} alt='Slika u galeriji' />
    }else if (isVideo) {
        return <video>
            <source src={url} type='video/mp4' />
        </video>
    }else {
        return <p>Ovaj fajl nije podržan.</p>
    }
}

export default MediaComponent