import React, { useState } from 'react'
import './birthdays-media.css'
import birthdayVideo1 from '../../../Assets/18.mp4'
import birthdayVideo2 from '../../../Assets/18-2.mp4'

const birthdaysVideos = [
    {
        id: 1,
        url: birthdayVideo1
    },
    {
        id: 2,
        url: birthdayVideo2
    },
]

const BirthdaysMedia = () => {

    const [videos] = useState(birthdaysVideos)
    const [videosToShow, setVideosToShow] = useState(3)

  return (
    <>
    <div className="birthdays-component">
        {
            videos.slice(0, videosToShow).map((music, index) => (
                <div className="birthdays-music" key={index} music={music}>
                    <video className='birthdays-video' controls>
                        <source src={music.url} type='video/mp4' />
                    </video>
                </div>
            ))
        }
    </div>
    <div className="birthdays-btn">
        <button disabled={videosToShow >= videos.length} onClick={() => setVideosToShow(videosToShow + 3)}>učitaj još</button>
    </div>
    </>
  )
}

export default BirthdaysMedia