import React, { useEffect } from 'react'
import GalleryCard from './GalleryCard/GalleryCard'
import './gallery-component.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

const GalleryComponent = ({data}) => {

    useEffect (() => {
        const carousel = document.querySelector('.gallery-content')
        const arrowBtns = document.querySelectorAll('.gallery-btn')
        const firstCardWidth = carousel.querySelector('.gallery-card').offsetWidth
        const carouselChildrens = [...carousel.children]

        let intervalId
        
        const autoPLay = () => {
            intervalId = setInterval(() => {
                carousel.scrollLeft += firstCardWidth

                // Check if we`ve reached the end and need to reset
                if(carousel.scrollLeft >= carousel.scrollWidth - carousel.offsetWidth){
                    carousel.scrollLeft = 0
                }
            }, 3000)
        }
        autoPLay()

        arrowBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                clearInterval(intervalId)
                carousel.scrollLeft += btn.id === 'left' ? -firstCardWidth : firstCardWidth
        
                if(carousel.scrollLeft === 0) {
                    carousel.scrollLeft += btn.id === 'left' ? carousel.scrollWidth - carousel.offsetWidth : firstCardWidth
                }else if (Math.ceil(carousel.scrollLeft) === carousel.scrollWidth - carousel.offsetWidth) {
                    carousel.scrollLeft += btn.id === 'right' ? carousel.scrollLeft = -carouselChildrens : -firstCardWidth
                }

                setTimeout(autoPLay)
            })
        })

        return () => {
            clearInterval(intervalId)
        }
    },[])

  return (
    <div className="gallery-component hidden-right">
        <div className="gallery-component-content">
                    <button id='left' className='gallery-btn'><AiOutlineLeft /></button>
        <div className="gallery-content">
            {
                data.map((gallery, index) => (
                    <GalleryCard gallery={gallery} key={index} />
                ))
            }
        </div>
        <button id='right' className='gallery-btn'><AiOutlineRight /></button>
        </div>
    </div>
  )
}

export default GalleryComponent