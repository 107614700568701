import React from "react";
import "./seminar-component.css";
import { Link } from "react-router-dom";
import seminarsImg from "../../../../Assets/Seminars/seminars.png";
import seminarsUnsplash from "../../../../Assets/unsplash2.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SeminarsComponent = () => {
  return (
    <div className="seminars-component hidden">
      <LazyLoadImage className="sc-dec" src={seminarsUnsplash} alt="Dekorativna slika" height={480} />
      <div className="seminars-component-content">
        <div className="seminars-component-column">
          <h1 className="hidden-left">Seminari</h1>
          <h3 className="hidden-left">
          Sala za seminare u restoranu Filmski Grad je idealan prostor za vaše poslovne događaje i seminare. Ova prostrana sala nudi udoban i funkcionalan ambijent koji omogućava produktivan rad i efikasno učenje. Sa modernim audio-vizuelnim opremom, udobnim stolicama i prijatnim osvetljenjem, sala je opremljena da zadovolji potrebe različitih vrsta seminara.
          </h3>
          <div className="seminars-component-btn hidden-left">
            <Link to="/seminari">Pogledaj još</Link>
          </div>
        </div>
        <div className="seminars-component-column">
          <div
            className="seminars-component-circle hidden-right"
          ><LazyLoadImage src={seminarsImg} alt="Slika seminara" height={480} /> </div>
        </div>
      </div>
    </div>
  );
};

export default SeminarsComponent;
