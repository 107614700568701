import React, { useState } from 'react'
import './about-us-vectors.css'
import { FaHome } from 'react-icons/fa'
import { AiTwotoneHome, AiFillVideoCamera, AiFillCamera } from 'react-icons/ai'
import { TbHorseToy } from 'react-icons/tb'
import { BiSolidParking } from 'react-icons/bi'
import { BsMusicPlayerFill, BsFillBalloonFill } from 'react-icons/bs'
import { GiTrumpet, GiClown } from 'react-icons/gi'
import { GrThreeDEffects, GrRestroomWomen } from 'react-icons/gr'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import backgroundImg from '../../../Assets/unsplash2.png'

const aboutUsVectors = [
    {
        id: 1,
        title: 'Restorani - Svečane sale',
        icon: <FaHome />,
    },
    {
        id: 2,
        title: 'Apartmani',
        icon: <AiTwotoneHome />,
    },
    {
        id: 3,
        title: 'Dečija igraonica',
        icon: <TbHorseToy />,
    },
    {
        id: 4,
        title: 'Parking',
        icon: <BiSolidParking />,
    },
    {
        id: 5,
        title: 'Ozvučenje',
        icon: <BsMusicPlayerFill />,
    },
    {
        id: 6,
        title: 'Trubači',
        icon: <GiTrumpet />,
    },
    {
        id: 7,
        title: 'Specijalni efekti',
        icon: <GrThreeDEffects />,
    },
    {
        id: 8,
        title: 'Dekoracija',
        icon: <BsFillBalloonFill />,
    },
    {
        id: 9,
        title: 'Video nadzor',
        icon: <AiFillVideoCamera />,
    },
    {
        id: 10,
        title: 'Hostese za doček',
        icon: <GrRestroomWomen />
    },
    {
        id: 11,
        title: 'Animatori',
        icon: <GiClown />
    },
    {
        id: 12,
        title: 'Fotografi',
        icon: <AiFillCamera />
    }
]

const AbousUsVectors = () => {

    const [activeClass, setActiveClass] = useState(0)

    const toggleActiveItem = (index) => {
      setActiveClass(index)
    }

  return (
    <div className="about-us-vectors-component">
        <LazyLoadImage src={backgroundImg} alt='Pozadina' />
        <div className="about-us-vectors-cc">
        <div className="about-us-vectors">
        <div className="about-us-vectors-container">
            <div className="about-us-vectors-icon">
                {
                    aboutUsVectors.map((vector, i) => (
                        <div onClick={() => toggleActiveItem(i)} className={`about-us-vectors-box ${i === activeClass ? 'active' : ''}`} style={{'--i': i + 1}} key={i}>
                            <span>{vector.icon}</span>
                        </div>
                    ))
                }
            </div>
            <div className="about-us-vectors-desc">
                {
                    activeClass >= 0 && (
                        <div className="about-us-vectors-content">
                            <span>{aboutUsVectors[activeClass].icon}</span>
                            <h3>{aboutUsVectors[activeClass].title}</h3>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
        </div>
    </div>
  )
}

export default AbousUsVectors