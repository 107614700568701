import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import ContactHero from "./Components/ContactHero/ContactHero";
import ContactForm from "./Components/ContactForm/ContactForm";
import Preloader from "../../Components/Preloader/Preloader";

const Contact = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Kontakt"
        description="Kontaktirajte nas - Filmski Grad je tu da vam pomogne ostvariti nezaboravne trenutke. Imate pitanja ili želite rezervisati našu salu? Naš tim je spreman da vam pruži odgovore i podršku. Obratite se danas i započnite planiranje svojeg događaja!"
        canonical="https://filmskigrad.rs/kontakt"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <ContactHero />
            <div className="page-content">
              <ContactForm />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Contact;
