import React from 'react'
import './preloader.css'
import OwlImg from '../../Assets/owl.png'

const Preloader = () => {
  return (
    <div className="preloader">
        <img loading='lazy' src={OwlImg} alt="Dok se stranica ne učita" />
        <h3>Molimo vas sačekajte</h3>
        <div className="preloader-dots">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  )
}

export default Preloader