import React from 'react'
import './not-found.css'
import notFoundImg from '../../Assets/owl.png'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
    <div className="not-found">
        <img src={notFoundImg} alt="Stranica nije pronađena" />
        <h1>Niste razbili internet, ali ne možemo da pronađemo ono što ste tražili.</h1>
        <h3>Vratite se na <Link to={'/'}>početnu stranicu</Link></h3>
    </div>
    </>
  )
}

export default NotFound