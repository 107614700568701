import React from 'react'
import './layout-text-left.css'
import { useLocation } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LayoutTextLeft = ({description, image, decoration}) => {
    
  const location = useLocation()

  return location.pathname === '/filmska-zvezda' ? (
    <div className="layout-text-left">
        <div className="layout-text-left-column hidden-left">
        <LazyLoadImage src={decoration} alt='Dekorativna slika' height={600} />
            <p className='pink'>{description}</p>
        </div>
        <div className="layout-text-left-column hidden-right">
            <div className="layout-text-left-box">
                <LazyLoadImage src={image} alt='Slika pored opisa' height={480} />
            </div>
        </div>
    </div>
  ) : (
    <div className="layout-text-left">
        <div className="layout-text-left-column hidden-left">
            <p>{description}</p>
        </div>
        <div className="layout-text-left-column hidden-right">
            <div className="layout-text-left-box">
            <LazyLoadImage src={image} alt='Slika pored opisa' height={480} />
            </div>
        </div>
    </div>
  )
}

export default LayoutTextLeft