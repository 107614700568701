import React, { useEffect, useState } from 'react'
import './movie-star-vectors.css'
import movieStarV1 from '../../../../Assets/MovieStar/MSG31.jpeg'
import movieStarV2 from '../../../../Assets/MovieStar/MSG32.jpg'
import movieStarV3 from '../../../../Assets/MovieStar/MSG33.jpeg'
import movieStarV4 from '../../../../Assets/MovieStar/MSG34.jpeg'
import movieStarV5 from '../../../../Assets/MovieStar/MSG35.jpeg'
import { MdChair } from 'react-icons/md'
import { SiCodeclimate } from 'react-icons/si'
import { AiFillSound } from 'react-icons/ai'
import { BsFillHouseDoorFill } from 'react-icons/bs'
import { ImPriceTag } from 'react-icons/im'
import movieStars from '../../../../Assets/stars.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const movieStarSlides = [
  {
    url: movieStarV1,
    icon: <MdChair />,
    title: 'Kapacitet 320 osoba'
  },
  {
    url: movieStarV2,
    icon: <SiCodeclimate />,
    title: 'Klimatizovana sala'
  },
  {
    url: movieStarV3,
    icon: <AiFillSound />,
    title: 'Odlično ozvučenje'
  },
  {
    url: movieStarV4,
    icon: <BsFillHouseDoorFill />,
    title: 'Apartmani'
  },
  {
    url: movieStarV5,
    icon: <ImPriceTag />,
    title: 'Pristupačne cene'
  },
]

const MovieStarVectors = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
  const movieStarVector = setInterval(() => {
    setCurrentSlide((currentSlide) =>
      currentSlide < movieStarSlides.length - 1 ? currentSlide + 1 : 0);
  }, 3000);

  return () => clearInterval(movieStarVector);
  },[]);

  return (
    <div className="movie-star-vectors">
        <LazyLoadImage src={movieStarSlides[currentSlide].url} alt='Filmska Zvezda' className="movie-star-vector-column" />
        <div className="movie-star-vector-column">
          {
            movieStarSlides.map((slide, slideIndex) => (
              <div className={slideIndex === currentSlide ? 'movie-star-vector pink-star' : 'movie-star-vector'} key={slideIndex} slide={slide}>
                <h1>{slide.icon}</h1>
                <h2>{slide.title}</h2>
              </div>
            ))
          }
          <LazyLoadImage src={movieStars} alt='Dekorativna slika' />
        </div>
    </div>
  )
}

export default MovieStarVectors