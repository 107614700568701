import React from 'react'
import './hero-slider-card.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HeroSliderCard = ({slide, index, current}) => {
  return (
    <div className="hero-slider-card">
      <div className="hero-slider-text">
        <h3>{slide.title}</h3>
      </div>
        {index === current && (
            <div className="hero-slider-slide">
              <LazyLoadImage src={slide.image} className='hero-slider-img' height={680} alt='Slajder slika' />
                <div className="hero-slider-text">
                  <h1>{slide.title}</h1>
                  <h3>{slide.desc}</h3>
                </div>
            </div>
        )}
    </div>
  )
}

export default HeroSliderCard