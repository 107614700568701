import React from 'react'
import './about-us-title.css'
import aboutUsTitleImg from '../../../Assets/aboutustitle.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutUsTitle = () => {
  return (
    <div className="about-us-title hidden">
      <LazyLoadImage src={aboutUsTitleImg} alt='O nama slika' />
        <div className="about-us-overlay"><h1>"Upoznajte Nas - Filmski Grad: Vaša Scena Za Nezaboravne Trenutke!"</h1></div>
    </div>
  )
}

export default AboutUsTitle