import React, { useState } from 'react'
import './music-component.css'

import Melody1 from '../../../Assets/Melodies/Melody1.mp4'
import Melody2 from '../../../Assets/Melodies/Melody2.mp4'
import Melody3 from '../../../Assets/Melodies/Melody3.mp4'
import musicVideo1 from '../../../Assets/Melodies/musicVideo.mp4'
import musicVideo2 from '../../../Assets/Melodies/musicVideo2.mp4'
import musicVideo3 from '../../../Assets/Melodies/musicVideo3.mp4'
import musicVideo4 from '../../../Assets/Melodies/musicVideo4.mp4'
import musicVideo5 from '../../../Assets/Melodies/musicVideo5.mp4'
import musicVideo6 from '../../../Assets/Melodies/musicVideo6.mp4'
import musicVideo7 from '../../../Assets/Melodies/musicVideo7.mp4'
import musicVideo8 from '../../../Assets/Melodies/musicVideo8.mp4'
import musicVideo9 from '../../../Assets/Melodies/musicVideo9.mp4'
import musicVideo10 from '../../../Assets/Melodies/musicVideo10.mp4'
import musicVideo11 from '../../../Assets/Melodies/musicVideo11.mp4'
import musicVideo12 from '../../../Assets/Melodies/musicVideo12.mp4'

const musicMelodies = [
    {
        id: 1,
        url: Melody1
    },
    {
        id: 2,
        url: Melody2
    },
    {
        id: 3,
        url: Melody3
    },
    {
        id: 4,
        url: musicVideo1
    },
    {
        id: 5,
        url: musicVideo2
    },
    {
        id: 6,
        url: musicVideo3
    },
    {
        id: 7,
        url: musicVideo4
    },
    {
        id: 8,
        url: musicVideo5
    },
    {
        id: 9,
        url: musicVideo6
    },
    {
        id: 10,
        url: musicVideo7
    },
    {
        id: 11,
        url: musicVideo8
    },
    {
        id: 12,
        url: musicVideo9
    },
    {
        id: 13,
        url: musicVideo10
    },
    {
        id: 14,
        url: musicVideo11
    },
]

const MusicComponent = () => {

    const [videos] = useState(musicMelodies)
    const [videosToShow, setVideosToShow] = useState(3)

  return (
    <>
    <div className="music-component">
        {
            videos.slice(0, videosToShow).map((melody, index) => (
                <>
                <div className="music-melody" key={index} melody={melody}>
                    <video className='music-video' controls>
                        <source src={melody.url} type='video/mp4' />
                    </video>
                </div>
                </>
            ))
        }
    </div>
    <div className='music-btn'>
        <button disabled={videosToShow >= videos.length} onClick={() => setVideosToShow(videosToShow + 3)}>učitaj još</button>
    </div>
    </>
  )
}

export default MusicComponent