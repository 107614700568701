import React from 'react'
import './weddings-open-concept.css'
import openConceptDec from '../../../Assets/unsplash2.png'

const WeddingsOpenConcept = () => {
  return (
    <div className="weddings-open-concept">
        <img src={openConceptDec} alt="Dekorativna slika" />
        <div className="weddings-open-concept-content">
            <h1 className='hidden-left'>Open koncept svadbe</h1>
            <p className='hidden-right'>
Open Koncept svadbe u restoranu "Filmski Grad" predstavljaju savremeni pristup tradicionalnim svadbama, pružajući mladencima mogućnost da stvore jedinstveno iskustvo za sebe i svoje goste. Ovaj restoran je poznat po svojoj filmskoj atmosferi i estetici, što dodatno bogati svadbenu proslavu.

Svadbeni koncept u "Filmskom Gradu" omogućava mladencima da prilagode svaki detalj svoje svadbe prema sopstvenom ukusu - od dekoracije i menija do muzike i zabave. Restoran nudi prelepe terase i dvorište koje pružaju predivan ambijent za ceremoniju i koktele, dok se večera i zabava mogu održavati u elegantnim unutrašnjim prostorima. Gosti će uživati u vrhunskoj hrani i piću koje će biti pažljivo pripremljeni, uz mogućnost izbora između različitih meni opcija.</p>
        </div>
    </div>
  )
}

export default WeddingsOpenConcept