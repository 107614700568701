import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { BirthdaysHeroData } from "../../Data/HeroSliderDatas";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import birthday1Img from "../../Assets/MovieCity/MCG1.png";
import birthday2Img from "../../Assets/MovieStar/MSG12.png";
import Preloader from "../../Components/Preloader/Preloader";
import BirthdaysMedia from "./Components/BirthdaysMedia";

const Birthdays = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Rođendani"
        canonical="https://filmskigrad.rs/rođendani"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
        description="Proslave rođendana u Filmskom Gradu - Oživite svoje rođendanske snove u našim prelepim i prilagodljivim salama. Otkrijte kako možemo učiniti vaš poseban dan još posebnijim. Pogledajte naše opcije za rođendane i stvorite nezaboravan trenutak uz nas!"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <HeroSlider slides={BirthdaysHeroData} />
            <div className="page-content">
              <LayoutTextLeft
                description="Naša raznolika ponuda sala pruža 
  neograničene mogućnosti za pravljenje 
  savršene zabave. Da li je vaš mali 
  superheroj spreman za akciju? Naša Super 
  Sala je idealna za junake u pelenama! Uz 
  vatromet boja, maskenbale i razne 
  avanture, mali junaci će se osećati kao 
  pravi spašavaoci sveta.Za male umetnike u 
  duši, tu je Kreativna Sala gde se boje 
  mešaju i mašte oslobađaju. Dečja 
  umetnička dela postaju prava remek-dela 
  koja će oduševiti rodbinu i prijatelje. Dobrodošli u čarobni svet naših posebnih sala za 
  rođendanske zabave! Ovde se svaka dečja 
  maštovita želja pretvara u stvarnost, a svaki 
  rođendan postaje nezaboravan događaj."
                image={birthday1Img}
              />
            </div>

            <div className="page-content">
              <LayoutTextRight
                description="Ali to nije sve! Naša Disco Sala pretvara 
  svaki rođendan u spektakularni plesni 
  podijum. Svjetlosni efekti, omiljena muzika 
  i plesne igre dovode atmosferu do usijanja, 
  a vaši mali plesači neće moći da prestanu 
  da se zabavljaju!
  
  I naravno, za one koji vole avanturu, tu je 
  Avanturistička Sala sa stazama za trčanje, 
  lavirintima i preprekama koje će testirati 
  hrabrost svakog mališana.
  
  Naš tim stručnjaka je tu da vam pomogne 
  pri organizaciji svake sitnice, od dekoracija 
  do menija. Vaša jedina briga će biti da 
  uhvatite svaki poseban trenutak i uživate u 
  osmesima srećnih lica."
                image={birthday2Img}
              />
              <BirthdaysMedia />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Birthdays;
