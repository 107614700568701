import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { WeddingsHeroData } from "../../Data/HeroSliderDatas";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import weddingImg1 from "../../Assets/Weddings/weddingImg.png";
import WeddingsComponent from "./Components/WeddingsComponent";
import FullGallery from "../../Components/GalleryComponent/FullGallery/FullGallery";
import { weddingsGallery } from "../../Data/GalleryData";
import WeddingsOpenConcept from "./Components/WeddingsOpenConcept";
import Preloader from "../../Components/Preloader/Preloader";

const Weddings = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  // Loader state
  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Venčanja"
        description="Vaša bajkovita svadba u Filmskom Gradu - Otkrijte našu posebnu ponudu za venčanja. Luksuzna sala, romantična atmosfera i besprekorna usluga čine vašu svadbu nezaboravnom. Osmislite svoj dan iz snova uz nas!"
        canonical="https://filmskigrad.rs/venčanja"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <HeroSlider slides={WeddingsHeroData} />
            <WeddingsOpenConcept />
            <div className="page-content">
              <LayoutTextLeft
                image={weddingImg1}
                description="Naše svadbene sale su čarobna mesta gde se snovi o večnoj ljubavi ostvaruju. Ove prelepe prostore karakteriše elegancija i šarm, stvarajući savršen ambijent za slavlje ljubavi i zajedničkog početka. U njima se susreću raskošno dekorisani prostori, prostrane plesne podijume i prijateljska atmosfera, stvarajući nezaboravno iskustvo za mladence i njihove goste. Svaka sala nosi jedinstveni pečat, od klasičnih i raskošnih do modernih i minimalističkih, pružajući raznolikost izbora kako bi se svaki par osećao kao da je njihov poseban dan upravo onakav kakav su zamišljali."
              />
            </div>
            <WeddingsComponent />
            <div className="page-content">
              <FullGallery title="Venčanja" data={weddingsGallery} />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Weddings;
