import React from 'react'
import './full-gallery.css'
import { Link } from 'react-router-dom'
import GalleryComponent from '../GalleryComponent'

const FullGallery = ({title, data}) => {
  return (
    <div className="full-gallery">
        <div className="full-gallery-title hidden-left">
            <h1>Galerija "{title}"</h1>
            <Link to='/galerija'>Pogledaj još</Link>
        </div>
        <GalleryComponent data={data} />
    </div>
  )
}

export default FullGallery