import React from 'react'
import './apartman-hero.css'
import apartmanImg1 from '../../../../Assets/apartman1.jpg'
import apartmanImg2 from '../../../../Assets/apartman2.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ApartmanHero = () => {
  return (
    <div className="apartman-hero-component hidden">
        <LazyLoadImage src={apartmanImg1} alt='Slika apartmana' />
        <div className="apartman-hero-overlay">
            <div className="apartman-hero-content">
                <div className="apartman-hero-grid">
                    <div className="apartman-hero-grid-column hidden-left">
                        <h1>Apartmani</h1>
                        <p>Apartmani restorana Filmski Grad predstavljaju udoban i elegantan smeštaj za goste koji dolaze da proslave svoje posebne događaje u prelepim salama ovog ekskluzivnog mesta. Svaki apartman je pažljivo osmišljen kako bi pružio najviši nivo udobnosti i luksuza, stvarajući nezaboravan boravak za goste.</p>
                    </div>
                    <div className="apartman-hero-grid-column hidden-right">
                        <div className="apartman-hero-box">
                            <LazyLoadImage src={apartmanImg2} alt='Slika apartmana' />
                            <div className="apartman-hero-box-content">
                                <h2>Apartmani za goste</h2>
                                <h2>Apartmani za venčanja</h2>
                                <h2>Apartmani za mladence</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ApartmanHero