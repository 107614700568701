import React from 'react'
import './seminars-vectors.css'
import seminarsImg2 from '../../../../Assets/Seminars/seminarsImg2.png'
import { MdChair } from 'react-icons/md'
import { SiCodeclimate } from 'react-icons/si'
import { AiFillSound } from 'react-icons/ai'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Vectors = [
  {
    id: 1,
    title: 'Kapacitet 320 osoba',
    icon: <MdChair />
  },
  {
    id: 2,
    title: 'Klimatizovana sala',
    icon: <SiCodeclimate />
  },
  {
    id: 3,
    title: 'Odlično ozvučenje',
    icon: <AiFillSound />
  },
]

const SeminarsVectors = () => {
  return (
    <div className="seminars-slider">
        <div className="seminars-circle-right hidden">
          <LazyLoadImage src={seminarsImg2} alt='Slika seminara' />
        </div>
        <div className="seminars-vector-content">
          {
            Vectors.map((vector, index) => (
              <div className="seminars-vector " key={index} vector={vector}>
                <h1>{vector.icon}</h1>
                <h1>{vector.title}</h1>
              </div>
            ))
          }
        </div>
    </div>
  )
}

export default SeminarsVectors