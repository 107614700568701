import React from 'react'
import { allGalleryCategories } from '../Gallery'
import { Link } from 'react-router-dom'
import './gallery-cards.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GalleryCards = () => {
  return (
    <div className="gallery-cards-component">
        {
            allGalleryCategories.map((gallery, i) => (
                <Link to={gallery.title} className="gallery-cards-card" gallery={gallery} key={i}>
                    <LazyLoadImage src={gallery.img} alt={gallery.title} />
                    <div className="gallery-cards-card-overlay">
                        <h1>{gallery.title}</h1>
                    </div>
                </Link>
            ))
        }
    </div>
  )
}

export default GalleryCards