import React, { useEffect } from 'react'
import './footer.css'
import { FaLocationDot, FaPhone } from 'react-icons/fa6'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineInstagram, AiOutlineCopyrightCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import FooterLogo from '../../Assets/logo.png'

const Footer = () => {

  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        })
      })
    }
        
    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className)
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className)
        observer.observe(el)
      })
    }
        
    observeHiddenElements('.hidden')
    observeHiddenElements('.hidden-left')
    observeHiddenElements('.hidden-right')
  },[])

  return (
    <div className="footer-section">
      <div className="footer-content">
        <div className="footer-column-1 footer-column hidden-left">
          <h1>Filmski Grad</h1>
          <h3>Na vratima će vas čekati naše čuveno gostoprimstvo, u restoranu tradicija i autentična kuhinja a u bašti arhitektura.</h3>
          <div className="footer-media">
            <Link target='_blank' to='https://www.instagram.com/filmski_grad_filmska_zvezda/' className="footer-svg"><AiOutlineInstagram /></Link>
            <Link target='_blank' to='https://www.facebook.com/restoranfilmskigrad/?locale=sr_RS' className="footer-svg"><FaFacebookF /></Link>
          </div>
          <div className="footer-location-phone">
          <Link target='_blank' to={'https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%E2%80%9E%D0%A4%D0%B8%D0%BB%D0%BC%D1%81%D0%BA%D0%B8+%D0%B3%D1%80%D0%B0%D0%B4%E2%80%9C/@44.7579705,20.4327939,17.26z/data=!4m15!1m8!3m7!1s0x475a71c6c315831f:0x4c9b9d8e09a22bb7!2z0JrQvdC10LfQsCDQktC40YjQtdGB0LvQsNCy0LAgODgsINCR0LXQvtCz0YDQsNC0!3b1!8m2!3d44.7571701!4d20.4353428!16s%2Fg%2F11hbj861jx!3m5!1s0x475a71c427ba290d:0x8e7679bf9708a7a9!8m2!3d44.7587038!4d20.4334015!16zL20vMGQ3bWQz?entry=ttu'} className="footer-svg footer-location">
            <FaLocationDot /><span>Kneza Višeslava 88, Beograd</span>
          </Link>
          <Link to={'tel:+011 3559180'} className='footer-svg footer-number'>
            <FaPhone /><span>Kontakt: 011 3559180</span>
          </Link>
          </div>
        </div>
        <div className="footer-column-2 footer-column hidden">
          <h1>Radno vreme</h1>
          <div className="footer-open-time">
            <h3>Pon - Ned</h3><h3>10AM - 8PM</h3>
          </div>
          <Link to="/" className="footer-logo">
            <img src={FooterLogo} alt="Logo restorana Filmski Grad" />
          </Link>
        </div>
        <div className="footer-column-3 footer-column hidden-right">
          <h1>Rezervacije</h1>
          <h3>Rezervišite vaše mesto <Link to="/kontakt">online</Link> ili nas pozovite na broj:</h3>
          <Link to="tel:+381 062 481 676">+381 062 481 676</Link>
        </div>
      </div>
      <div className="footer-copyright hidden">
        <p>Copyright<span><AiOutlineCopyrightCircle /></span>2023 | Restoran Filmski Grad</p>
      </div>
    </div>
  )
}

export default Footer