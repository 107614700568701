import { GiFlowers, GiStairsCake } from 'react-icons/gi'
import { FaGlassMartiniAlt } from 'react-icons/fa'

export const HomeVectors = [
    {
        id: 1,
        title: 'Venčanja',
        icon: <GiFlowers />,
        link: '/venčanja'
    },
    {
        id: 2,
        title: 'Seminari',
        icon: <FaGlassMartiniAlt />,
        link: '/seminari'
    },
    {
        id: 3,
        title: 'Rođendani',
        icon: <GiStairsCake />,
        link: '/rođendani'
    },
]