import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import { MusicData } from "../../Data/HeroSliderDatas";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import musicImg1 from "../../Assets/fgband4.jpg";
import musicImg2 from "../../Assets/fgband5.jpg";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import Preloader from "../../Components/Preloader/Preloader";
import MusicComponent from "./MusicComponent/MusicComponent";

const Music = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Muzika"
        description="Doživite nezaboravne trenutke uz živu muziku u restoranu 'Filmski Grad'. Naša muzika stvara savršenu atmosferu za sve vrste proslava koje možete organizirati u našim predivnim salama. Uživajte u vrhunskim izvođačima i raznolikom repertoaru koji će dodati poseban šarm vašem događaju."
        canonical="https://filmskigrad.rs/muzika"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <HeroSlider slides={MusicData} />
            <div className="page-content">
              <LayoutTextLeft
                description="
Mladja FG Band je talentovani muzički sastav koji svojim izvanrednim nastupima doprinosi atmosferi i zabavi u restoranu Filmski Grad. Ovaj sastav čini nekoliko mladih i strastvenih muzičara koji izvode raznovrsne muzičke žanrove, prilagođavajući se potrebama i željama gostiju. Njihov repertoar obuhvata sve, od popularnih hitova do klasičnih balada i narodnih pesama."
                image={musicImg1}
              />
              <LayoutTextRight
                description=" Mladja FG Band se brine da svaka pesma bude izvedena sa strašću i preciznošću, prilagođavajući se ukusu i željama gostiju. Njihova muzika je ključni sastojak svake proslave u restoranu Filmski Grad, stvarajući nezaboravan doživljaj za sve prisutne. Uz Mladja FG Band, svaka proslava postaje autentično muzičko putovanje koje će gosti dugo pamtiti."
                image={musicImg2}
              />
              <MusicComponent />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Music;
