import React from 'react'
import './apartman-banner.css'
import unsplashImg from '../../../../Assets/unsplash2.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ApartmanBanner = () => {
  return (
    <div className="apartman-banner-component hidden">
      <LazyLoadImage src={unsplashImg} alt='Dekorativna slika' />
        <div className="apartman-banner-text">
            <div className="apartman-banner-content">
                <h3>Gosti koji dolaze sopstvenim prevozom mogu besplatno koristiti naš parking. Po potrebi, nudimo dodatne pakete i usluge kako bismo vaš boravak učinili još posebnijim, kao što su voće i šampanjac po dolasku ili spa tretmani.</h3>
            </div>
        </div>
    </div>
  )
}

export default ApartmanBanner