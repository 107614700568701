import React, { useEffect, useState } from 'react'
import { FaLocationDot, FaPhone } from 'react-icons/fa6'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineInstagram, AiOutlineMenu, AiOutlineClose, AiOutlineSend, AiFillMobile } from 'react-icons/ai'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './navbar.css'
import NavbarLogo from '../../Assets/logo.png'
import navbarDecImg from '../../Assets/unsplash2.png'

const NavLinks = [
    {
        id: 1,
        title: "Početna",
        link: '/'
    },
    {
        id: 2,
        title: "O nama",
        link: '/o-nama'
    },
    {
        id: 3,
        title: "Filmski Grad",
        link: '/filmski-grad'
    },
    {
        id: 4,
        title: "Filmska Zvezda",
        link: '/filmska-zvezda'
    },
    {
        id: 5,
        title: "Apartmani",
        link: '/apartmani'
    },
    {
        id: 6,
        title: "Muzika",
        link: '/muzika'
    },
    {
        id: 7,
        title: "Venčanja",
        link: '/venčanja'
    },
    {
        id: 8,
        title: "Seminari",
        link: '/seminari',
    },
    {
        id: 9,
        title: "Rođendani",
        link: '/rođendani'
    },
    {
        id: 10,
        title: "Kontakt",
        link: '/kontakt'
    },
    {
        id: 11,
        title: "Galerija",
        link: '/galerija'
    },
]

const Navbar = () => {

    const location = useLocation()

    /*----- Change Navbar Color -----*/
    const [changeColor, setChangeColor] = useState(false)
    const changeNavColor = () => {
        if(window.scrollY > 0) {
            setChangeColor(true)
        }else {
            setChangeColor(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNavColor)

        return () => {
            window.removeEventListener('sceoll', changeNavColor)
        }
    },[])

    /*----- Fixed For Relative Navbar -----*/
    const [navbar, setNavbar] = useState(false)
    const fixedNavbar = () => {
        if(window.scrollY > 0) {
            setNavbar(true)
        }else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', fixedNavbar)

        return () => {
            window.removeEventListener('scroll', fixedNavbar)
        }
    },[])

    /*----- Show/Hide Navbar Links Functionality -----*/
    const [visibleLinks, setVisibleLinks] = useState(false)

    const showNavbarLinks = () => {
        setVisibleLinks(true)
    }

    const hideNavbaeLinks = () => {
        setVisibleLinks(false)
    }

  return location.pathname === '/' ? (
    <div className={`navbar-section ${changeColor ? 'color' : ''}`}>
        <div className="navbar-content">
            <div className="navbar-top-content">
                <Link target='_blank' to='https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%E2%80%9E%D0%A4%D0%B8%D0%BB%D0%BC%D1%81%D0%BA%D0%B8+%D0%B3%D1%80%D0%B0%D0%B4%E2%80%9C/@44.7579705,20.4327939,17.26z/data=!4m15!1m8!3m7!1s0x475a71c6c315831f:0x4c9b9d8e09a22bb7!2z0JrQvdC10LfQsCDQktC40YjQtdGB0LvQsNCy0LAgODgsINCR0LXQvtCz0YDQsNC0!3b1!8m2!3d44.7571701!4d20.4353428!16s%2Fg%2F11hbj861jx!3m5!1s0x475a71c427ba290d:0x8e7679bf9708a7a9!8m2!3d44.7587038!4d20.4334015!16zL20vMGQ3bWQz?entry=ttu' className='navbar-svg navbar-location'><FaLocationDot /><span>Kneza Višeslava 88, Beograd</span></Link>
                <div className="navbar-media">
                <Link target='_blank' to='https://www.instagram.com/filmski_grad_filmska_zvezda/' className='navbar-svg navbar-instagram'><AiOutlineInstagram /></Link>
                <Link target='_blank' to='https://www.facebook.com/restoranfilmskigrad/?locale=sr_RS' className='navbar-svg navbar-facebook'><FaFacebookF /></Link>
                <Link to='tel:+0113559180' className='navbar-svg navbar-number'><FaPhone /><span>011 3559180</span></Link>
                <Link to='tel:+381062481676' className='navbar-svg navbar-phone'><AiFillMobile /><span>062 481 676</span></Link>
                </div>            
            </div>
            <div className="navbar-bottom-content">
                <Link to='/' className="navbar-logo">
                    <img src={NavbarLogo} alt='Logo restorana Filmski Grad' />
                </Link>
                <div className="navbar-interactions">
                    <Link to={'/kontakt'} className='navbar-svg navbar-send'><AiOutlineSend /></Link>
                    <button className='navbar-menu' onClick={showNavbarLinks}><AiOutlineMenu /></button>
                </div>
            </div>
            <div className={`navbar-sidebar ${visibleLinks ? 'appear' : 'disappear'}`}>
                <img src={navbarDecImg} alt="Dekorativna slika" />
                <div className="sidebar-close" onClick={hideNavbaeLinks}><AiOutlineClose /></div>
                {
                    NavLinks.map((link, i) => (
                        <NavLink key={i} to={link.link}>{link.title}</NavLink>
                    ))
                }
            </div>
        </div>
    </div>
  ) : location.pathname === '/filmska-zvezda' ? (
    <div className={`navbar-section ${changeColor ? 'color' : ''}`}>
    <div className="navbar-content">
        <div className="navbar-top-content">
            <Link target='_blank' to='https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%E2%80%9E%D0%A4%D0%B8%D0%BB%D0%BC%D1%81%D0%BA%D0%B8+%D0%B3%D1%80%D0%B0%D0%B4%E2%80%9C/@44.7579705,20.4327939,17.26z/data=!4m15!1m8!3m7!1s0x475a71c6c315831f:0x4c9b9d8e09a22bb7!2z0JrQvdC10LfQsCDQktC40YjQtdGB0LvQsNCy0LAgODgsINCR0LXQvtCz0YDQsNC0!3b1!8m2!3d44.7571701!4d20.4353428!16s%2Fg%2F11hbj861jx!3m5!1s0x475a71c427ba290d:0x8e7679bf9708a7a9!8m2!3d44.7587038!4d20.4334015!16zL20vMGQ3bWQz?entry=ttu' className='navbar-svg navbar-location'><FaLocationDot /><span>Kneza Višeslava 88, Beograd</span></Link>
            <div className="navbar-media">
            <Link target='_blank' to='https://www.instagram.com/filmski_grad_filmska_zvezda/' className='navbar-svg navbar-instagram'><AiOutlineInstagram /></Link>
            <Link target='_blank' to='https://www.facebook.com/restoranfilmskigrad/?locale=sr_RS' className='navbar-svg navbar-facebook'><FaFacebookF /></Link>
            <Link to='tel:+0113559180' className='navbar-svg navbar-number'><FaPhone /><span>011 3559180</span></Link>
            <Link to='tel:+381062481676' className='navbar-svg navbar-phone'><AiFillMobile /><span>062 481 676</span></Link>
            </div>            
        </div>
        <div className="navbar-bottom-content">
            <Link to='/' className="navbar-logo">
                <img src={NavbarLogo} alt='Logo restorana Filmski Grad' />
            </Link>
            <div className="navbar-interactions">
                <Link to={'/kontakt'} className='navbar-svg navbar-send'><AiOutlineSend /></Link>
                <button className='navbar-menu' onClick={showNavbarLinks}><AiOutlineMenu /></button>
            </div>
        </div>
        <div className={`navbar-sidebar ${visibleLinks ? 'appear' : 'disappear'}`}>
        <img src={navbarDecImg} alt="Dekorativna slika" />
            <div className="sidebar-close" onClick={hideNavbaeLinks}><AiOutlineClose /></div>
            {
                NavLinks.map((link, i) => (
                    <NavLink key={i} to={link.link}>{link.title}</NavLink>
                ))
            }
        </div>
    </div>
</div>
  ) : location.pathname === '/filmski-grad' ? (
    <div className={`navbar-section ${changeColor ? 'color' : ''}`}>
    <div className="navbar-content">
        <div className="navbar-top-content">
            <Link target='_blank' to='https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%E2%80%9E%D0%A4%D0%B8%D0%BB%D0%BC%D1%81%D0%BA%D0%B8+%D0%B3%D1%80%D0%B0%D0%B4%E2%80%9C/@44.7579705,20.4327939,17.26z/data=!4m15!1m8!3m7!1s0x475a71c6c315831f:0x4c9b9d8e09a22bb7!2z0JrQvdC10LfQsCDQktC40YjQtdGB0LvQsNCy0LAgODgsINCR0LXQvtCz0YDQsNC0!3b1!8m2!3d44.7571701!4d20.4353428!16s%2Fg%2F11hbj861jx!3m5!1s0x475a71c427ba290d:0x8e7679bf9708a7a9!8m2!3d44.7587038!4d20.4334015!16zL20vMGQ3bWQz?entry=ttu' className='navbar-svg navbar-location'><FaLocationDot /><span>Kneza Višeslava 88, Beograd</span></Link>
            <div className="navbar-media">
            <Link target='_blank' to='https://www.instagram.com/filmski_grad_filmska_zvezda/' className='navbar-svg navbar-instagram'><AiOutlineInstagram /></Link>
            <Link target='_blank' to='https://www.facebook.com/restoranfilmskigrad/?locale=sr_RS' className='navbar-svg navbar-facebook'><FaFacebookF /></Link>
            <Link to='tel:+0113559180' className='navbar-svg navbar-number'><FaPhone /><span>011 3559180</span></Link>
            <Link to='tel:+381062481676' className='navbar-svg navbar-phone'><AiFillMobile /><span>062 481 676</span></Link>
            </div>            
        </div>
        <div className="navbar-bottom-content">
            <Link to='/' className="navbar-logo">
                <img src={NavbarLogo} alt='Logo restorana Filmski Grad' />
            </Link>
            <div className="navbar-interactions">
                <Link to={'/kontakt'} className='navbar-svg navbar-send'><AiOutlineSend /></Link>
                <button className='navbar-menu' onClick={showNavbarLinks}><AiOutlineMenu /></button>
            </div>
        </div>
        <div className={`navbar-sidebar ${visibleLinks ? 'appear' : 'disappear'}`}>
        <img src={navbarDecImg} alt="Dekorativna slika" />
            <div className="sidebar-close" onClick={hideNavbaeLinks}><AiOutlineClose /></div>
            {
                NavLinks.map((link, i) => (
                    <NavLink key={i} to={link.link}>{link.title}</NavLink>
                ))
            }
        </div>
    </div>
</div>
  ) : (
    <div className={`navbar-relative ${navbar ? 'fixed' : ''}`}>
    <div className="navbar-content">
        <div className="navbar-top-content">
            <Link target='_blank' to='https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%E2%80%9E%D0%A4%D0%B8%D0%BB%D0%BC%D1%81%D0%BA%D0%B8+%D0%B3%D1%80%D0%B0%D0%B4%E2%80%9C/@44.7579705,20.4327939,17.26z/data=!4m15!1m8!3m7!1s0x475a71c6c315831f:0x4c9b9d8e09a22bb7!2z0JrQvdC10LfQsCDQktC40YjQtdGB0LvQsNCy0LAgODgsINCR0LXQvtCz0YDQsNC0!3b1!8m2!3d44.7571701!4d20.4353428!16s%2Fg%2F11hbj861jx!3m5!1s0x475a71c427ba290d:0x8e7679bf9708a7a9!8m2!3d44.7587038!4d20.4334015!16zL20vMGQ3bWQz?entry=ttu' className='navbar-svg navbar-location'><FaLocationDot /><span>Kneza Višeslava 88, Beograd</span></Link>
            <div className="navbar-media">
            <Link target='_blank' to='https://www.instagram.com/filmski_grad_filmska_zvezda/' className='navbar-svg navbar-instagram'><AiOutlineInstagram /></Link>
            <Link target='_blank' to='https://www.facebook.com/restoranfilmskigrad/?locale=sr_RS' className='navbar-svg navbar-facebook'><FaFacebookF /></Link>
            <Link to='tel:+0113559180' className='navbar-svg navbar-number'><FaPhone /><span>011 3559180</span></Link>
            <Link to='tel:+381062481676' className='navbar-svg navbar-phone'><AiFillMobile /><span>062 481 676</span></Link>
            </div>            
        </div>
        <div className="navbar-bottom-content">
            <Link to='/' className="navbar-logo">
                <img src={NavbarLogo} alt='Logo restorana Filmski Grad' />
            </Link>
            <div className="navbar-interactions">
                <Link to={'/kontakt'} className='navbar-svg navbar-send'><AiOutlineSend /></Link>
                <button className='navbar-menu' onClick={showNavbarLinks}><AiOutlineMenu /></button>
            </div>
        </div>
        <div className={`navbar-sidebar ${visibleLinks ? 'appear' : 'disappear'}`}>
        <img src={navbarDecImg} alt="Dekorativna slika" />
            <div className="sidebar-close" onClick={hideNavbaeLinks}><AiOutlineClose /></div>
            {
                NavLinks.map((link, i) => (
                    <NavLink key={i} to={link.link}>{link.title}</NavLink>
                ))
            }
        </div>
    </div>
</div>
  )
}

export default Navbar