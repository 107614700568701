import WG2 from '../Assets/Weddings/wg2.png'
import WG3 from '../Assets/Weddings/wg3.png'
import WG4 from '../Assets/Weddings/wg4.png'
import WG5 from '../Assets/Weddings/wg5.png'
import WG6 from '../Assets/Weddings/wg6.png'
import WG7 from '../Assets/Weddings/wg7.png'
import WG8 from '../Assets/Weddings/wg8.png'
import WG9 from '../Assets/Weddings/wg9.png'
import WG10 from '../Assets/Weddings/wg10.png'

import MSG1 from '../Assets/MovieStar/MSG26.jpeg'
import MSG2 from '../Assets/MovieStar/MSG27.jpeg'
import MSG3 from '../Assets/MovieStar/MSG28.jpg'
import MSG4 from '../Assets/MovieStar/MSG29.jpeg'
import MSG5 from '../Assets/MovieStar/MSG30.jpeg'
import MSG6 from '../Assets/MovieStar/MSG31.jpeg'
import MSG7 from '../Assets/MovieStar/MSG32.jpg'
import MSG8 from '../Assets/MovieStar/MSG33.jpeg'
import MSG9 from '../Assets/MovieStar/MSG34.jpeg'
import MSG10 from '../Assets/MovieStar/MSG35.jpeg'

import MCG4 from '../Assets/MovieCity/MCG26.jpeg'
import MCG5 from '../Assets/MovieCity/MCG27.jpeg'
import MCG6 from '../Assets/MovieCity/MCG28.jpg'
import MCG7 from '../Assets/MovieCity/MCG29.jpg'
import MCG8 from '../Assets/MovieCity/MCG30.jpg'
import MCG9 from '../Assets/MovieCity/MCG31.jpeg'
import MCG10 from '../Assets/MovieCity/MCG32.jpeg'

export const movieStarGallery = [
    {
        id: 1,
        image: MSG1
    },
    {
        id: 2,
        image: MSG2
    },
    {
        id: 3,
        image: MSG3
    },
    {
        id: 4,
        image: MSG4
    },
    {
        id: 5,
        image: MSG5
    },
    {
        id: 6,
        image: MSG6
    },
    {
        id: 7,
        image: MSG7
    },
    {
        id: 8,
        image: MSG8
    },
    {
        id: 9,
        image: MSG9
    },
    {
        id: 10,
        image: MSG10
    },
]

export const weddingsGallery = [
    {
        id: 1,
        image: WG2
    },
    {
        id: 2,
        image: WG3
    },
    {
        id: 3,
        image: WG4
    },
    {
        id: 4,
        image: WG5
    },
    {
        id: 5,
        image: WG6
    },
    {
        id: 6,
        image: WG7
    },
    {
        id: 7,
        image: WG8
    },
    {
        id: 8,
        image: WG9
    },
    {
        id: 9,
        image: WG10
    },
]

export const movieCityGallery = [
    {
        id: 4,
        image: MCG4
    },
    {
        id: 5,
        image: MCG5
    },
    {
        id: 6,
        image: MCG6
    },
    {
        id: 7,
        image: MCG7
    },
    {
        id: 8,
        image: MCG8
    },
    {
        id: 9,
        image: MCG9
    },
    {
        id: 10,
        image: MCG10
    },
]