import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import ApartmanHero from "./Components/ApartmanHero/ApartmanHero";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import apartmanImg from "../../Assets/apartman3.jpeg";
import ApartmanBanner from "./Components/ApartmanBanner/ApartmanBanner";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import apartmanImg2 from "../../Assets/apartman4.jpg";
import Preloader from "../../Components/Preloader/Preloader";

const Apartmans = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Apartmani"
        description="Iznajmite udobne apartmane u neposrednoj blizini restorana 'Filmski Grad'. Naši apartmani pružaju udoban i luksuzan smeštaj za vaše goste ili vam omogućuju da produžite svoj boravak nakon proslave. S modernim sadržajem, prelepim pogledom i izvrsnim uslugama, naši apartmani čine vaš boravak nezaboravnim."
        canonical="https://filmskigrad.rs/apartmani"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <ApartmanHero />
            <div className="page-content">
              <LayoutTextLeft
                image={apartmanImg}
                description="Naši apartmani se nalaze u neposrednoj blizini naših sala za proslave, što ih čini praktičnim izborom za goste koji žele da se brzo presele iz proslave u svoj smeštaj. Na raspolaganju vam je room service koji vam omogućava da uživate u ukusnim jelima iz restorana u udobnosti vašeg apartmana."
              />
            </div>
            <ApartmanBanner />
            <div className="page-content">
              <LayoutTextRight
                image={apartmanImg2}
                description="Naši apartmani su opremljeni luksuznim nameštajem, visokokvalitetnim posteljinama i svim modernim sadržajima kako biste se osećali kao kod kuće. Prostrane sobe sa udobnim krevetima omogućavaju vam da se odmorite i opustite nakon proslave, a terase ili balkoni pružaju prelep pogled na okolinu."
              />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Apartmans;
