import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import HeroSliderCard from './HeroSliderCard/HeroSliderCard'
import './hero-slider.css'

const HeroSlider = ({slides}) => {

    const [ current, setCurrent ] = useState(0)
    const length = slides.length
    const timeout = useRef(null)

    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current => (current === length - 1 ? 0 : current + 1))
        }

        timeout.current = setTimeout(nextSlide, 3000)

        return function() {
            if(timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    },[current, length])

    const nextSlide = () => {
        if(timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        if(timeout.current) {
            clearTimeout(timeout.current)
        }
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

  return (
    <div className="hero-slider">
        <div className="hero-slider-wrap">
            <button className='hero-slider-btn hs-prev' onClick={prevSlide}><AiOutlineLeft /></button>
            {
                slides.map((slide, index) => (
                    <HeroSliderCard slide={slide} index={index} key={index} current={current} />
                ))
            }
            <button className='hero-slider-btn hs-next' onClick={nextSlide}><AiOutlineRight /></button>
        </div>
    </div>
  )
}

export default HeroSlider