import React, { useEffect, useState } from "react";
import HelmetContent from "../../Layout/Helmet/Helmet";
import Layout from "../../Layout/Layout";
import Hero from "../../Components/Hero/Hero";
import video from "../../Assets/MovieStar/MovieStarVideo.mp4";
import LayoutTextLeft from "../../Components/LayoutTextLeft/LayoutTextLeft";
import star1 from "../../Assets/MovieStar/MSG26.jpeg";
import stars from "../../Assets/stars.png";
import MovieStarComponentSecond from "./Components/MovieStarComponentSeconds/MovieStarComponentSeconds";
import MovieStarVectors from "./Components/MovieStarVectors/MovieStarVectors";
import FGBand from "../../Components/FGBand/FGBand";
import LayoutTextRight from "../../Components/LayoutTextRight/LayoutTextRight";
import movieStarsImg from "../../Assets/MovieStar/MSG30.jpeg";
import FullGallery from "../../Components/GalleryComponent/FullGallery/FullGallery";
import { movieStarGallery } from "../../Data/GalleryData";
import Preloader from "../../Components/Preloader/Preloader";

const MovieStar = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Filmska Zvezda"
        description="Filmska Zvezda - Naša ekskluzivna dvorana, postala je glavna atrakcija u Filmskom Gradu. Očarajte svoje goste luksuznim prostorom, visokim standardima i posebnim trenucima. Rezervišite Filmsku Zvezdu i napravite svoj događaj nezaboravnim!"
        canonical="https://filmskigrad.rs/filmska-zvezda"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-container">
            <Hero
              video={video}
              title="Filmska Zvezda"
              description='Sala "Filmska Zvezda" je elegantan i šarmantan prostor koji odiše glamurom i sofisticiranim šarmom, koji je uvek prepoznatljiv za sebe. Ova sala nije povezana s filmskom industrijom, ali će zasigurno postati zvezda među salama za proslave i događaje svake vrste.'
              link={"/filmski-grad"}
            />
            <div className="page-content">
              <LayoutTextLeft
                image={star1}
                decoration={stars}
                description='Dizajn sale "Filmska Zvezda" ističe se svojim luksuznim i modernim izgledom. Zidovi su obloženi mekim, tamnim tonovima koji stvaraju intimnu i ugodnu atmosferu. Veliki prozori obasjavaju prostor prirodnim svetlom tokom dana, dok su svetiljke i lusteri diskretno postavljeni kako bi stvorili romantičnu i elegantnu atmosferu tokom večernjih događaja.'
              />
            </div>
            <MovieStarComponentSecond />
            <div className="page-content">
              <MovieStarVectors />
            </div>
            <FGBand />
            <div className="page-content">
              <LayoutTextRight
                image={movieStarsImg}
                description='Sala je prilagodljiva za različite vrste proslava i događaja, uključujući venčanja, rođendane, korporativne seminare i koktele. Kapacitet sale omogućuje udoban smeštaj velikog broja gostiju, dok se stolovi i stolice mogu lako prilagoditi prema potrebama organizatora.
  
                Za zabave i venčanja, sala "Filmska Zvezda" ima elegantan plesni podij, idealan za prvi ples ili živu glazbu koja će oživeti atmosferu. Tu je i dobro opremljen bar koji nudi širok izbor pića i koktela za vaše goste.'
                decoration={stars}
              />
              <FullGallery data={movieStarGallery} title="Filmske Zvezde" />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default MovieStar;
