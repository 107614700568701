import React, { useEffect, useRef, useState } from "react";
import "./home-hero-slider.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { allHomeSlides } from "../../../../Data/HeroSliderDatas";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HomeHeroSLider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = allHomeSlides.length;
  const slideTimeout = useRef(null);

    useEffect(() => {
      const nextHomeSlide = () => {
          setCurrentSlide(currentSlide => (currentSlide === slideLength - 1 ? 0 : currentSlide + 1))
      }

      slideTimeout.currentSlide = setTimeout(nextHomeSlide, 5000)

      return function() {
          if(slideTimeout.currentSlide) {
              clearTimeout(slideTimeout.currentSlide)
          }
      }
  },[currentSlide, slideLength])

  const nextHomeSlide = () => {
    if (slideTimeout.currentSlide) {
      clearTimeout(slideTimeout.currentSlide);
    }
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevHomeSlide = () => {
    if (slideTimeout.currentSlide) {
      clearTimeout(slideTimeout.currentSlide);
    }
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  if (!Array.isArray(allHomeSlides) || allHomeSlides.slideLength <= 0) {
    return null;
  }

  return (
    <div className="home-hero-slider">
      <div className="home-hero-slider-wrap">
        <button
          className="home-hero-slider-btn home-slider-prev"
          onClick={prevHomeSlide}
        >
          <AiOutlineLeft />
        </button>
        {allHomeSlides.map((slide, index) => (
          <div className="home-hero-slide-card" slide={slide} key={index}>
            {index === currentSlide && (
              <div className="home-hero-slider-slide">
                <LazyLoadImage src={slide.image} alt={slide.title} height={628} />
                <div className="home-hero-slide-overlay">
                  <div className="home-hero-slide-content">
                    <h1>{slide.title}</h1>
                    <div className="home-slider-card-desc">
                      <p>{slide.desc}</p>
                    </div>
                    <Link to={slide.link}>
                      <button className="slider-card-btn">pogledaj više</button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        <button
          className="home-hero-slider-btn home-slider-next"
          onClick={nextHomeSlide}
        >
          <AiOutlineRight />
        </button>
      </div>
    </div>
  );
};

export default HomeHeroSLider;
