import React from 'react'
import './about-us-dark.css'
import aboutUsDarkImg from '../../../Assets/unsplash2.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutUsDark = () => {
  return (
    <div className="about-us-dark">
        <LazyLoadImage src={aboutUsDarkImg} alt='Dekorativna slika' />
        <div className="about-us-dark-content">
            <div className="about-us-dark-column">
                <div className="about-us-dark-hall hidden-left">
                    <h1 className='hidden-left'>"Filmski Grad"</h1>

                    <div className="about-us-dark-star hidden">
                        <h1>Filmska Zvezda</h1>
                    </div>
                </div>
            </div>
            <div className="about-us-dark-column">
                <h2 className='hidden-right'>Dobrodošli u svet Filmskog Grada, vašeg jedinstvenog doma za kreiranje nezaboravnih trenutaka! Naša ponuda obuhvata prostrane dvorane i konferencijske sale opremljene najsavremenijom opremom i tehničkim mogućnostima kako bismo zadovoljili sve vaše potrebe.</h2>
            </div>
        </div>
    </div>
  )
}

export default AboutUsDark