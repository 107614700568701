import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import HelmetContent from "../../Layout/Helmet/Helmet";
import AboutUsComponent from "./Components/AboutUsComponent/AboutUsComponent";
import MovieStarComponent from "./Components/MovieStarComponent/MovieStarComponent";
import OpenConcept from "./Components/OpenConcept/OpenConcept";
import Hero from "../../Components/Hero/Hero";
import Vectors from "../../Components/Vectors/Vectors";
import { HomeVectors } from "../../Data/VectorsData";
import FGBand from "../../Components/FGBand/FGBand";
import SeminarsComponent from "./Components/SeminarsComponent/SeminarsComponent";
import HomeHeroSLider from "./Components/HomeHeroSlider/HomeHeroSlider";
import fullVideoMC from "../../Assets/fullVideoMC.mp4";
import Preloader from "../../Components/Preloader/Preloader";
import HomeVectorsComponent from "./Components/HomeVectorsComponent/HomeVectorsComponent";

const Home = () => {
  /*----- Intersection Observer Animations -----*/
  useEffect(() => {
    const revealOnIntersection = (className) => {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
    };

    const observeHiddenElements = (className) => {
      const hiddenElements = document.querySelectorAll(className);
      hiddenElements.forEach((el) => {
        const observer = revealOnIntersection(className);
        observer.observe(el);
      });
    };

    observeHiddenElements(".hidden");
    observeHiddenElements(".hidden-left");
    observeHiddenElements(".hidden-right");
  }, []);

  // Loader state
  const [isLoading, setIsLoading] = useState(true);

  // Loader
  useEffect(() => {
    const preLoader = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };

    preLoader();
  }, []);

  return (
    <>
      <HelmetContent
        title="Početna"
        description="Dobrodošli u Filmski Grad - Vaša scena za nezaboravne proslave i događaje. Prilagodljivi prostori, vrhunski kulinarski doživljaji i stručna podrška za vaše posebne trenutke. Kontaktirajte nas i stvorite nezaboravan događaj danas!"
        canonical="https://filmskigrad.rs/"
        google="5PbR-JslWOg6OAm3L6FQ2slAEka-UPnNsaK71U90qfs"
      />
      {isLoading ? (
        <Preloader />
      ) : (
        <Layout>
          <div className="page-conainer">
            <HomeVectorsComponent />
            <HomeHeroSLider />
            <div className="page-content">
              <AboutUsComponent />
            </div>
            <Hero
              video={fullVideoMC}
              title="Restoran Filmski Grad"
              description='Restoran "Filmski Grad" je šarmantno i jedinstveno mesto za organizaciju različitih proslava i događaja u srcu grada. Iako ime možda asocira na svet filma, restoran nema nikakve veze sa kinematografijom; umesto toga, ova lokacija je savršeno opremljena i dizajnirana kako bi zadovoljila sve potrebe vaše posebne proslave. Restoran "Filmski Grad" takođe nudi izvanrednu uslugu hrane i pića. Iskusni kuvari pripremaju raznovrsne menije koji zadovoljavaju različite ukuse i dijetetske potrebe, a stručno osoblje restorana će se pobrinuti da svaka proslava bude nezaboravna.'
              link={"/filmski-grad"}
            />
            <MovieStarComponent />
            <div className="page-content">
              <OpenConcept />
              <Vectors vectors={HomeVectors} />
            </div>
            <FGBand />
            <SeminarsComponent />
          </div>
        </Layout>
      )}
    </>
  );
};

export default Home;
