import React from "react";
import "./about-us-component.css";
import AboutUsImg from "../../../../Assets/about.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutUsComponent = () => {
  return (
    <div className="about-us-component hidden">
      <div className="about-us-content">
        <div className="about-us-column-1 hidden-left">
          <LazyLoadImage src={AboutUsImg} height={420} alt="Slika za o nama sekciju" />
        </div>
        <div className="about-us-column-2 hidden-right">
          <h1 className="hidden-right">O nama</h1>
          <p>
            Naše sale nude širok spektar prostora za sve vrste događaja, od
            poslovnih sastanaka i seminara do svečanih proslava i venčanja.
            Ono što nas izdvaja je naša posvećenost pružanju vrhunskog iskustva
            našim klijentima. Svaka sala koju iznajmljujemo je pažljivo uređena
            i opremljena kako bi se prilagodila vašim potrebama. Ono što nas
            čini posebnima je naša promenjiva kuhinja koja se prilagođava vašim
            željama i preferencijama. Naši profesionalni kuvari kreiraju
            raznolike menije koji će zadovoljiti ukuse svih vaših gostiju.
          </p>
          <Link to="/o-nama">Pogledaj još</Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
