import React from 'react'
import './gallery-card.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GalleryCard = ({gallery}) => {
  return (
    <div className="gallery-card">
      <div className="gallery-card-inner">
        <LazyLoadImage src={gallery.image} alt='Odvoje slika iz galerije' height={380} />
      </div>
    </div>
  )
}

export default GalleryCard